import * as THREE from 'three';
import TWEEN from 'three/addons/libs/tween.module.js';
import EnemyAbstract from './enemy-abstract';
import { LEVEL_CONFIG } from '../../data/level-config';
import { GAME_CONFIG } from '../../data/game-config';
import { ENEMY_STATE, ENEMY_TYPE } from '../data/enemy-data';
import { isEqualsPositions,randomFromArray } from '../../../../../core/helpers/helpers';
import { DIRECTION, MAP_TYPE,ROTATION_BY_DIRECTION } from '../../data/game-data';
import { GLOBAL_VARIABLES } from '../../data/global-variables';
import Loader from '../../../../../core/loader';
import { SKELETON_CONFIG, SKELETON_MOVEMENT_STATE } from '../data/skeleton-config';
import { ANIMATION_BY_REAL_NAME, ANIMATION_TYPE } from '../data/animations-data';
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js';

export default class Skeleton extends EnemyAbstract {
  constructor(mixer) {
    super();

    this._type = ENEMY_TYPE.Skeleton;

    this._positionHelper = null;
    this._arrowHelper = null;
    this._currentDirection = null;
    this._rotationTween = null;
    this._view = null;
    this._spawnShowTween = null;
    this._spawnHideTween = null;
    this._mixer = mixer;

    this._animations = {};

    this._moveSpeed = 0.3;
    this._movementState = SKELETON_MOVEMENT_STATE.Idle;
    this.num=0;
    this._init();
  }

  update(dt) {
    // if (this._state !== ENEMY_STATE.Active) {
    //   return;
    // }

    if(this._state !== ENEMY_STATE.Active|| this._movementState !== SKELETON_MOVEMENT_STATE.Moving){
      return
    }
    const cellSize = GAME_CONFIG.cellSize;//game_config // 僵尸尺寸
    const currentLevel = GLOBAL_VARIABLES.currentLevel;// 当前关卡等级
    const fieldConfig = LEVEL_CONFIG[currentLevel].field;
    const speed = this._moveSpeed * dt * SKELETON_CONFIG.speedMultiplier;
  
    switch (this._currentDirection) {
      case DIRECTION.Up:
        this._viewGroup.position.z -= speed;
        break;
      case DIRECTION.Down:
        this._viewGroup.position.z += speed;
        break;
      case DIRECTION.Left:
        this._viewGroup.position.x -= speed;
        break;
      case DIRECTION.Right:
        this._viewGroup.position.x += speed;
        break;
    }
 
    // console.log(this._viewGroup.position.z,this._viewGroup.position.x ,(-fieldConfig.rows * cellSize * 0.5 + cellSize * 0.5))
    if (this._viewGroup.position.z < (-fieldConfig.rows * cellSize * 0.5 + cellSize * 0.5)) {
     
      this._rotateToDirection(DIRECTION.Down);
     
    }

    if (this._viewGroup.position.z > (fieldConfig.rows * cellSize * 0.5 - cellSize * 0.5)) {
      this._rotateToDirection(DIRECTION.Up);
      
 
    }

    if (this._viewGroup.position.x < (-fieldConfig.columns * cellSize * 0.5 + cellSize * 0.5)) {
      this._rotateToDirection(DIRECTION.Right);
      
     
    }

    if (this._viewGroup.position.x > (fieldConfig.columns * cellSize * 0.5 - cellSize * 0.5)) {
      this._rotateToDirection(DIRECTION.Left);
     
    }

    const newPosition = this.getPositionFromView();
    if (!isEqualsPositions(this._currentPosition, newPosition)) {
      this._updateSkeletonMap(newPosition);
      this.events.post('positionChanged');
      this._currentPosition = newPosition;

      if (GAME_CONFIG.helpers) {
        this._positionHelper.visible = true;
        this._arrowHelper.visible = true;
      }
    }
  
  }

  spawn() {
    this.show();
    // this._moveSpeed = Math.random() * (SKELETON_CONFIG.moveSpeed.max - SKELETON_CONFIG.moveSpeed.min) + SKELETON_CONFIG.moveSpeed.min;
    this._spawnShowTween = this._showSpawnAnimation();
    this._spawnShowTween.scaleTween.onComplete(() => {
      this._state = ENEMY_STATE.Active;
      this._movementState = SKELETON_MOVEMENT_STATE.Moving;
      this.setBodyActivity(true);
      this._updateSkeletonMap(this._currentPosition);
      this.events.post('positionChanged');

    });
  }

  kill() {
    this._state = ENEMY_STATE.Idle;
    this._movementState = SKELETON_MOVEMENT_STATE.Idle;
    this.setBodyActivity(false);
    this.stopTweens();
    const skeletonMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Skeleton];
    
    
    skeletonMap[this._currentPosition.row][this._currentPosition.column] = null;

    
    this._spawnHideTween = this._showHideAnimation();
    this._spawnHideTween.scaleTween.onComplete(() => {
      this.hide();
      this.reset();
      this.events.post('onKilled', this);
    });
  }

  setSpawnPosition() {
    for(const key in this._animations){
      const anim=this._animations[key];
      const action =this._mixer.clipAction(anim, this._view);
  
      action.stop();
    }
    this._playAnimation('walk')
    const randomPosition = this._getRandomPosition();

    this.setPosition(randomPosition);

  

    const availableDirections = this._getAvailableDirections();
    const randomDirection = randomFromArray(availableDirections);
    
    
    this.setDirection(randomDirection);
  }

  reset() {
    for(const key in this._animations){
      const anim=this._animations[key];
      const action =this._mixer.clipAction(anim, this._view);
  
      action.stop();
    }
    this._currentDirection = null;
    this._movementState = SKELETON_MOVEMENT_STATE.Idle;
    this.stopTweens();
  }

  stopTweens() {    
    this._rotationTween?.stop();
    this._spawnHideTween?.scaleTween?.stop();
    this._spawnShowTween?.scaleTween?.stop();
  }

  _rotateToDirection(direction) {
    if (this._currentDirection === direction) {
      return;
    }

    this._movementState = SKELETON_MOVEMENT_STATE.Turning;

    let targetAngle = ROTATION_BY_DIRECTION[direction];

    if (this._currentDirection === DIRECTION.Down && direction === DIRECTION.Left) {
      targetAngle = -Math.PI / 2;
    }

    if (this._currentDirection === DIRECTION.Left && direction === DIRECTION.Down) {
      targetAngle = Math.PI * 2;
    }

    this._currentDirection = direction;
    const duration = (Math.abs(this._viewGroup.rotation.y - targetAngle) * SKELETON_CONFIG.turnRate) / SKELETON_CONFIG.speedMultiplier;

    this._rotationTween = new TWEEN.Tween(this._viewGroup.rotation)
      .to({ y: targetAngle }, duration)
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .start()
      .onComplete(() => {
        this._movementState = SKELETON_MOVEMENT_STATE.Moving;
      });
  }
  _showSpawnAnimation() {
    this._viewGroup.scale.set(0, 0, 0)

    const duration = SKELETON_CONFIG.spawnAnimationDuration / SKELETON_CONFIG.speedMultiplier;

    const scaleTween = new TWEEN.Tween(this._viewGroup.scale)
      .to({ x: 1, y: 1, z: 1 }, duration)
      .easing(TWEEN.Easing.Sinusoidal.Out)
      .start();

    return { scaleTween };
  }

  _showHideAnimation() {
    const duration = SKELETON_CONFIG.spawnAnimationDuration / SKELETON_CONFIG.speedMultiplier;

    const scaleTween = new TWEEN.Tween(this._viewGroup.scale)
      .to({ x: 0, y: 0, z: 0 }, duration)
      .easing(TWEEN.Easing.Sinusoidal.Out)
      .start();

    return { scaleTween };
  }

  _updateSkeletonMap(newPosition) {
    const skeletonMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Skeleton];

    if (this._currentPosition) {
      skeletonMap[this._currentPosition.row][this._currentPosition.column] = null;
  
    }
    skeletonMap[newPosition.row][newPosition.column] = this;

    // skeletonMap[newPosition.row][newPosition.column] = this;
  }

  _getRandomPosition() {
    const obstaclesMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Obstacle];
    const evilPumpkinMap = GLOBAL_VARIABLES.maps[MAP_TYPE.EvilPumpkin];
    const skeletonMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Skeleton];
    const playerPosition = GLOBAL_VARIABLES.playerPosition;

    const randomRow = Math.floor(Math.random() * obstaclesMap.length);
    const randomColumn = Math.floor(Math.random() * obstaclesMap[0].length);

    if (obstaclesMap[randomRow][randomColumn] || evilPumpkinMap[randomRow][randomColumn] || skeletonMap[randomRow][randomColumn] || (randomRow === playerPosition.row && randomColumn === playerPosition.column)) {
      return this._getRandomPosition();
    }

    return { row: randomRow, column: randomColumn };
  }

  _getAvailableDirections() {
    const availableDirections = [];

    const currentPosition = this._currentPosition;
    const obstacleMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Obstacle];
    const evilPumpkinMap = GLOBAL_VARIABLES.maps[MAP_TYPE.EvilPumpkin];
    const skeletonMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Skeleton];

    if (currentPosition.row - 1 >= 0 && !obstacleMap[currentPosition.row - 1][currentPosition.column] && !evilPumpkinMap[currentPosition.row - 1][currentPosition.column] && !skeletonMap[currentPosition.row - 1][currentPosition.column]) {
      availableDirections.push(DIRECTION.Up);
    }

    if (currentPosition.row + 1 < obstacleMap.length && !obstacleMap[currentPosition.row + 1][currentPosition.column] && !evilPumpkinMap[currentPosition.row + 1][currentPosition.column] && !skeletonMap[currentPosition.row + 1][currentPosition.column]) {
      availableDirections.push(DIRECTION.Down);
    }

    if (currentPosition.column - 1 >= 0 && !obstacleMap[currentPosition.row][currentPosition.column - 1] && !evilPumpkinMap[currentPosition.row][currentPosition.column - 1] && !skeletonMap[currentPosition.row][currentPosition.column - 1]) {
      availableDirections.push(DIRECTION.Left);
    }

    if (currentPosition.column + 1 < obstacleMap[0].length && !obstacleMap[currentPosition.row][currentPosition.column + 1] && !evilPumpkinMap[currentPosition.row][currentPosition.column + 1] && !skeletonMap[currentPosition.row][currentPosition.column + 1]) {
      availableDirections.push(DIRECTION.Right);
    }

    return availableDirections;
  }

  _init() {
    this._initView(); 


    this._initAnimations();

  


    this.hide(true);
  }

  _initView() {
    const viewGroup = this._viewGroup = new THREE.Group();
    this.add(viewGroup);
    // const hat=Loader.assets['hat1'].scene.clone();
    // hat.position.z=-0.234;
    // hat.scale.set(3.5,3.5,3.5);
    const hat2=Loader.assets['hat2'].scene.clone();
    hat2.scale.set(3.5,3.5,3.5)
    const scene = Loader.assets['zombie'].scene;
    
    const view = this._view = SkeletonUtils.clone(scene);
    // const num=Math.random()*10;
    // if(num<3){
    //   view.getObjectByName('mixamorigHeadTop_End').add(hat)
    // }else if(num>3&&num<=7){
      view.getObjectByName('mixamorigHeadTop_End').add(hat2)
    // }
    
    viewGroup.add(view);

    const scale = 0.7;
    view.scale.set(scale, scale, scale);

    view.traverse(child => {
      if (child.isMesh) {
        child.castShadow = true;
      }
    });
    // console.log('---新模型')
  }

 

  _initAnimations() {
    const animations = Loader.assets['zombie'].animations;
    const scope=this;
    animations.forEach(animation => {
      // console.log(animation,scope._view,scope._mixer,'---scope._mixer')
      const action = scope._mixer.clipAction(animation,scope._view);
      // animation.name=animation.name.toUpperCase();
      // console.log(animation.name,'---animation.name')
      // scope._setWeight(action, 1,0.5);
      // action.play();
      action.stop();

      const animationType = ANIMATION_BY_REAL_NAME[animation.name];
      // console.log(animationType,animation.name,ANIMATION_BY_REAL_NAME)
      if (animationType) {
        scope._animations[animationType] = animation;
      }
    });
  }
 
  _playAnimation(animationName) {
    
    const actionAnim = this._animations[animationName];
    // console.log(animationName,'---animationName')
    const action =this._mixer.clipAction(actionAnim, this._view);
    // console.log(this._animations,animationName)
    // action.setEffectiveTimeScale(0.5)
    // if(animationName=="WALK"||animationName=="IDLE"){
      action.timeScale=1.2;
      action.play();
      // console.log(action.timeScale,'---timeScale')
      // 
    // }
    // action.play();
  }

  _setWeight(action, weight,timeScale) {
    action.enabled = true;
    action.setEffectiveTimeScale(timeScale);
    action.setEffectiveWeight(weight);
  }
}
