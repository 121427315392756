import * as THREE from 'three';
import ConsumableAbstract from './consumable-abstract';
import { CONSUMABLES_CONFIG, CONSUMABLE_TYPE } from '../data/consumables-config';
import Loader from '../../../../../core/loader';

import PowerUpParticles from './power-up-particles/power-up-particles';
export default class BigCandy extends ConsumableAbstract {
  constructor() {
    super();

    this._type = CONSUMABLE_TYPE.BigCandy;
    this._config = CONSUMABLES_CONFIG[this._type];

    this._scale = 0.4;
    this._positionY = 0;
    this._particles=null;
    this._init();
  }

  _init() {
    const view = Loader.assets['player-sunflower'].scene.clone();
    this._viewGroup.add(view);



    view.castShadow = true;
    view.receiveShadow = true;
    this._initParticles();
  }
  update(dt) {
  
    this._particles.update(dt);
  }
  show() {
    super.show();
    this._particles.show();
  }

  hide() {
    super.hide();
    this._particles.hide();
  }
  _initParticles() {
    const particles = this._particles = new PowerUpParticles(this._type);
    this.add(particles);
  }
}
