import * as THREE from 'three';
import Obstacle from './obstacle';
import { LEVEL_CONFIG } from '../data/level-config';
import ObjectPositionHelper from '../helpers/object-position-helper';
import { GAME_OBJECT_TYPE, MAP_TYPE } from '../data/game-data';
import Delayed from '../../../../core/helpers/delayed-call';

import { GLOBAL_VARIABLES } from '../data/global-variables';
import { SOUNDS_CONFIG } from '../../../../core/configs/sounds-config';
import Loader from '../../../../core/loader';
import SCENE_CONFIG from '../../../../core/configs/scene-config';

export default class ObstaclesController extends THREE.Group {
  constructor(audioListener) {
    super();

    this._audioListener = audioListener;

    this._obstacles = [];
    this._positionHelpers = [];

    this._fallSound = null;
    this._globalVolume = SOUNDS_CONFIG.masterVolume;

    this._initFallSound();
  }

  createObstacles() {
    const currentLevel =GLOBAL_VARIABLES.currentLevel;

    const obstaclesConfig = LEVEL_CONFIG[currentLevel].obstacles;
  

    const obstacles=this.createFileds(8,8,obstaclesConfig);//
    this._createObstaclesByMap(obstacles);//墓碑数量 根据等级 发生变化
    // this._createPositionsHelper();
    this._initMap();
  }
  createFileds(row,col,obstaclesConfig){
    const arr=[],_this=this;
    for(let i=0;i<row;i++){
      for(let j=0;j<col;j++){
        if(i==4&&j==3){
  
        }else{
          const tem={
            row:i,
            column:j
          };
     
          arr.push(tem)
        }
      }
    }
    obstaclesConfig.forEach(item=>{
      item.position=_this.getAndRemoveRandomElement(arr)
    });
    return obstaclesConfig
  }
  getAndRemoveRandomElement(array) {  
    // 随机选择一个索引  
    let randomIndex = Math.floor(Math.random() * array.length);  
    
    // 获取并删除随机元素  
    let randomElement = array.splice(randomIndex, 1)[0];  
    
    // 返回随机元素  
    return randomElement;  
  }  
  showIntro() {
    let delay = 0;
    const delayStep = 130;

    const obstacleMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Obstacle];

    for (let row = 0; row < obstacleMap.length; row++) {
      for (let column = 0; column < obstacleMap[0].length; column++) {
        if (obstacleMap[row][column]) {
          const obstacle = this._getObstacleByPosition({ row, column });
          obstacle.show();
          obstacle.showIntro(delay);

          const additionalDelay = SCENE_CONFIG.isMobile ? 700 : 400;
          Delayed.call(delay + additionalDelay, () => {
            this._fallSound.position.copy(obstacle.position);
            this._playSound(this._fallSound);
          });

          delay += delayStep;
        }
      }
    }

    return Delayed.call(delay + delayStep * 1.5, () => {});
  }

  debugChangedHelper() {
    for (let i = 0; i < this._obstacles.length; i++) {
      const obstacle = this._obstacles[i];
      const positionHelper = this._positionHelpers[i];
      positionHelper.debugChangedHelper();
      positionHelper.setPosition(obstacle.getPosition());
    }
  }

  reset() {
    this._obstacles.forEach(obstacle => this.remove(obstacle));
    this._obstacles = [];

    this._positionHelpers.forEach(positionHelper => this.remove(positionHelper));
    this._positionHelpers = [];
  }

  onSoundChanged() {
    const obstacleFallSoundVolume = SOUNDS_CONFIG.enabled ? SOUNDS_CONFIG.masterVolume * SOUNDS_CONFIG.obstacleFallSoundVolume : 0;
    this._fallSound.setVolume(obstacleFallSoundVolume);
  }

  _getObstacleByPosition(position) {
    for (let i = 0; i < this._obstacles.length; i++) {
      const obstacle = this._obstacles[i];

      if (obstacle.getPosition().row === position.row && obstacle.getPosition().column === position.column) {
        return obstacle;
      }
    }

    return null;
  }

  _initMap() {
    const obstacleMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Obstacle];

    for (let i = 0; i < this._obstacles.length; i++) {
      const obstacle = this._obstacles[i];
      const position = obstacle.getPosition();

      obstacleMap[position.row][position.column] = obstacle;
    }
  }




  _createObstaclesByMap(obstaclesConfig) {
   
    for (let i = 0; i < obstaclesConfig.length; i++) {
      const config = obstaclesConfig[i];

      const obstacle = new Obstacle(config.type);
      this.add(obstacle);

      obstacle.setPosition(config.position);
      obstacle.hide();
      this._obstacles.push(obstacle);
    }
  }

  _createPositionsHelper() {
    for (let i = 0; i < this._obstacles.length; i++) {
      const obstacle = this._obstacles[i];

      const obstaclePositionHelper = new ObjectPositionHelper(GAME_OBJECT_TYPE.Obstacle);
      this.add(obstaclePositionHelper);

      obstaclePositionHelper.setPosition(obstacle.getPosition());
      obstaclePositionHelper.show();

      this._positionHelpers.push(obstaclePositionHelper);
    }
  }

  _playSound(sound) {
    if (sound.isPlaying) {
      sound.stop();
    }

    sound.play();
  }

  _initFallSound() {
    const fallSound = this._fallSound = new THREE.PositionalAudio(this._audioListener);
    this.add(fallSound);

    fallSound.setRefDistance(10);
    fallSound.setVolume(this._globalVolume * SOUNDS_CONFIG.obstacleFallSoundVolume);

    Loader.events.on('onAudioLoaded', () => {
      fallSound.setBuffer(Loader.assets['obstacle-fall']);
    });
  }
}