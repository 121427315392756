const ANIMATION_TYPE = {

  attack: 'attack',
  idle:'idle',
  walk: 'walk',
  death:'death'
}

const ANIMATION_BY_REAL_NAME = {
  'death':ANIMATION_TYPE.death,
  'attack': ANIMATION_TYPE.attack,
  'idle':ANIMATION_TYPE.idle,
  // 'KayKit Animated Character|KayKit Animated Character|Jump': ANIMATION_TYPE.Jump,
  // 'KayKit Animated Character|KayKit Animated Character|Run': ANIMATION_TYPE.Run,
  // 'KayKit Animated Character|KayKit Animated Character|Shoot(2h)Bo': ANIMATION_TYPE.ShootBow,
  'walk': ANIMATION_TYPE.walk,
}

// ------------------------------------------------------------

// const ANIMATION_TYPE = {
//   Attack: 'ATTACK', // remove
//   AttackCombo: 'ATTACK_COMBO', // remove
//   AttackSpin: 'ATTACK_SPIN', // remove
//   BasePose: 'BASE_POSE', // remove
//   Block: 'BLOCK', // remove
//   Cheer: 'CHEER',
//   Climbing: 'CLIMBING', // remove
//   Dance: 'DANCE',
//   DashBack: 'DASH_BACK',
//   DashFront: 'DASH_FRONT',
//   DashLeft: 'DASH_LEFT',
//   DashRight: 'DASH_RIGHT',
//   Defeat: 'DEFEAT', // remove
//   HeavyAttack: 'HEAVY_ATTACK', // remove
//   Hop: 'HOP',
//   Idle: 'IDLE',
//   Interact: 'INTERACT', // remove
//   Jump: 'JUMP',
//   LayingDown: 'LAYING_DOWN', // remove
//   PickUp: 'PICK_UP', // remove
//   Roll: 'ROLL', // remove
//   Run: 'RUN',
//   Shoot_01: 'SHOOT_01', // remove
//   Shoot_02: 'SHOOT_02', // remove
//   ShootBow: 'SHOOT_BOW',
//   Shooting_01: 'SHOOTING_01', // remove
//   Shooting_02: 'SHOOTING_02', // remove
//   Throw: 'THROW', // remove
//   Walk: 'WALK',
//   Wave: 'WAVE', // remove
// }



export { ANIMATION_TYPE, ANIMATION_BY_REAL_NAME };
