const CONSUMABLE_TYPE = {// 奖励 道具配置类型
  SmallCandy: 'SMALL_CANDY',//小糖果
  BigCandy: 'BIG_CANDY',//大糖果
  BoosterCandyPlayerSpeed: 'BOOSTER_CANDY_PLAYER_SPEED',//玩家提速
  BoosterCandyPlayerInvulnerability: 'BOOSTER_CANDY_PLAYER_INVULNERABILITY',// 玩家刀枪不入
  BoosterCandyEnemiesSlow: 'BOOSTER_CANDY_ENEMIES_SLOW',//敌人移动减速
}

const CONSUMABLES_CONFIG = {
  [CONSUMABLE_TYPE.SmallCandy]: {
    spawnTime: { min: 3000, max: 6000 },
    lifeTime: { min: 10000, max: 15000 },
    chanceToSpawn: 0.9,
    particlesColor: 0xD800f5,//紫色
  },
  [CONSUMABLE_TYPE.BigCandy]: {
    spawnTime: { min: 7000, max: 10000 },
    lifeTime: { min: 10000, max: 15000 },
    particlesColor: 0xffb638,//绿色
    chanceToSpawn: 0.5,
  },
  [CONSUMABLE_TYPE.BoosterCandyPlayerSpeed]: {
    lifeTime: { min: 20000, max: 25000 },// 停留时间范围
    name: '豌豆加速剂',
    color: 0x00aa00,//绿色
    particlesColor: 0x00aa00,
    progressBarWidth: 100,
    duration: 18000,
    speedMultiplier: 1.8,
  },
  [CONSUMABLE_TYPE.BoosterCandyPlayerInvulnerability]: {
    lifeTime: { min: 20000, max: 25000 },
    name: '金钟罩',
    color: 0xf1ec6d,//红色
    particlesColor: 0xf1ec6d,
    progressBarWidth: 190,
    duration: 12000,
  },
  [CONSUMABLE_TYPE.BoosterCandyEnemiesSlow]: {
    lifeTime: { min: 20000, max: 25000 },
    name: '僵尸迟钝剂',
    color: 0xbf7827,//蓝色
    particlesColor: 0x1aa0dc,
    progressBarWidth: 130,
    duration: 18000,
    speedMultiplier: 0.4,
  },
  boosterCandyConfig: {
    spawnTime: { min: 10000, max: 15000 },
    chanceToSpawn: 0.65,
  },
}

export {
  CONSUMABLE_TYPE,
  CONSUMABLES_CONFIG,
};
