import * as THREE from 'three';
import { Black } from 'black-engine';
import DEBUG_CONFIG from "../../configs/debug-config";
import RendererStats from 'three-webgl-stats';
import Stats from 'three/addons/libs/stats.module.js';

import { OrbitControls } from "three/addons/controls/OrbitControls";

export default class Scene3DDebugMenu {//OrbitControls
  constructor(scene, camera, renderer) {
    this._scene = scene;
    this._camera = camera;
    this._renderer = renderer;



    this._orbitControls = null;// 相机控制器（放到这 不合适


    this._isAssetsLoaded = false;

    this._init();
  }





  update() {
    if (DEBUG_CONFIG.orbitControls) {
      this._orbitControls.update();
    }


  }

  showAfterAssetsLoad() {
    this._isAssetsLoaded = true;



    if (DEBUG_CONFIG.orbitControls) {
      this._orbitControls.enabled = true;
    }


  }

  getOrbitControls() {
    return this._orbitControls;
  }

  _init() {


    this._initOrbitControls();


  }





  _initOrbitControls() {
    const orbitControls = this._orbitControls = new OrbitControls(this._camera, Black.engine.containerElement);

    orbitControls.target.set(0, 0, 0);

    orbitControls.enableDamping = true;
    orbitControls.dampingFactor = 0.07;

    if (!this._isAssetsLoaded) {
      orbitControls.enabled = false;
    }
  }



  

 

  onOrbitControlsClick(orbitControlsState) {
    if (orbitControlsState) {
      if (!this._orbitControls) {
        this._initOrbitControls();
      }

      this._orbitControls.enabled = true;
    } else {
      this._orbitControls.enabled = false;
    }
  }
}
