import * as THREE from "three";
import TWEEN from "three/addons/libs/tween.module.js";

import { MessageDispatcher } from "black-engine";
import { LEVEL_CONFIG } from "../data/level-config";
import { GAME_CONFIG } from "../data/game-config";
import {
  isEqualsPositions,


} from "../../../../core/helpers/helpers";
import {
  DIRECTION,
  GAME_STATE,
  MAP_TYPE,
  ROTATION_BY_DIRECTION,
} from "../data/game-data";
import { GLOBAL_VARIABLES } from "../data/global-variables";

export default class peaBullet extends THREE.Group {
  constructor(audioListener) {
    //子弹发射声音
    super();

    this.events = new MessageDispatcher();

    this._audioListener = audioListener;

    this._lifeTimer = null;
    this._moveSpeed = 0.04;
    this._currentPosition = null;
    this._movementState = "IDLE"; // GHOST_MOVEMENT_STATE.Idle;

    this._view = null;

    this._viewGroup = null;
    this.textureLoader = new THREE.TextureLoader();
    this._initBullte();

    // setInterval( this.peaInit,2000);
  }
 
  update(dt) {
    if (this._movementState !== "MOVING") {
      //GHOST_MOVEMENT_STATE.Moving
      return;
    }

    const cellSize = GAME_CONFIG.cellSize; //game_config // 格子尺寸
    const currentLevel = GLOBAL_VARIABLES.currentLevel; // 当前关卡等级
    const fieldConfig = LEVEL_CONFIG[currentLevel].field;
    // console.log('currentLevel--',currentLevel,'---LEVEL_CONFIG--',LEVEL_CONFIG)
    const speed = this._moveSpeed;

    // const maxInclineAngle = speed * GHOST_CONFIG.inclineCoeff;
    // this._view.rotation.x = lerp(this._view.rotation.x, maxInclineAngle, dt);
    // console.log(this._currentDirection,'--this._currentDirection')
    switch (this._currentDirection) {
      case DIRECTION.Up:
        this._viewGroup.position.z -= speed; //子弹移动
        break;
      case DIRECTION.Down:
        this._viewGroup.position.z += speed;
        break;
      case DIRECTION.Left:
        this._viewGroup.position.x -= speed;
        break;
      case DIRECTION.Right:
        this._viewGroup.position.x += speed;
        break;
    }

    if ( this._viewGroup.position.z < -fieldConfig.rows * cellSize * 0.5 + cellSize * 0.5) {
      // 子弹 到头 销毁掉 最好延迟2秒
      this.kill();
    }

    if (this._viewGroup.position.z >fieldConfig.rows * cellSize * 0.5 - cellSize * 0.5) {
      // this._rotateToDirection(DIRECTION.Up);
      this.kill();
    }

    if (this._viewGroup.position.x <-fieldConfig.columns * cellSize * 0.5 + cellSize * 0.5) {
      this.kill();
    }

    if (this._viewGroup.position.x >fieldConfig.columns * cellSize * 0.5 - cellSize * 0.5) {
      this.kill();
    }

    const newPosition = this.getPositionFromView();
    //  console.log(newPosition,'---newPosition',this._currentPosition)
    if (!isEqualsPositions(this._currentPosition, newPosition)) {
      this._updateBulletMap(newPosition);
      // this.events.post('positionChanged');
      this._currentPosition = newPosition;
    }
  }
  getPositionFromView() {
    const cellSize = GAME_CONFIG.cellSize;
    const currentLevel = GLOBAL_VARIABLES.currentLevel;
    const fieldConfig = LEVEL_CONFIG[currentLevel].field;
    const position = {
      row: Math.round(
        (this._viewGroup.position.z +
          fieldConfig.rows * cellSize * 0.5 -
          cellSize * 0.5) /
          cellSize
      ),
      column: Math.round(
        (this._viewGroup.position.x +
          fieldConfig.columns * cellSize * 0.5 -
          cellSize * 0.5) /
          cellSize
      ),
    };

    return position;
  }
  spawnBullet = () => {
   
    if (this._lifeTimer) {
      this._lifeTimer.stop();
    }
    // this._spawnShowTween = this._showSpawnAnimation();
    // this._spawnShowTween.opacityTween.onComplete(() => {
      // this._view.material.opacity = 0.5;
      this._view.scale.set(0.1,0.1,0.1)
      this._viewGroup.position.y = 0.2;
      this._state = "ACTIVE";
      this._movementState = "MOVING";
      // this.setBodyActivity(true);
      this._updateBulletMap(this._currentPosition);
      this.events.post("positionChanged");
      this.setSpawnPosition(this._currentPosition)
      this._showSpawnAnimation()
      // this._startLifeTimer();
    // });
  };
  kill() {
    this._state = "IDLE";
    this._movementState = "IDLE";
    // this.setBodyActivity(false);

    const bulletMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Bullet];
    this._removeBulletFromArray(
      bulletMap[this._currentPosition.row][this._currentPosition.column]
    );

    this._spawnHideTween = this._showHideAnimation();
    this._spawnHideTween.positionTween.onComplete(() => {
      this.hide();
      this.reset();
      this.events.post('onKilled', this);//事件传递
    });
  }
  setSpawnPosition(position) {
    //设置子弹初始位置  需要根据 豌豆的位置 来设置
 
    this.setPosition(position);
  }
  reset() {
    this._currentDirection = null;
    this._movementState = "IDLE"; // GHOST_MOVEMENT_STATE.Idle;
    this.stopTweens();
  }
  stopTweens() {
    // this._rotationTween?.stop();
    this._spawnHideTween?.positionTween?.stop();
    this._spawnHideTween?.opacityTween?.stop();
    this._spawnHideTween?.inclineTween?.stop();
    this._spawnShowTween?.position?.stop();
    this._spawnShowTween?.opacity?.stop();

    this._lifeTimer?.stop();
  }
  _showSpawnAnimation() {
   


    const duration = 1000;

    const opacityTween = new TWEEN.Tween(this._view.scale)
      .to({ x: 1.4,y:1.4,z:1.4   }, duration) //GHOST_CONFIG.inactiveBodyOpacity
      .easing(TWEEN.Easing.Sinusoidal.Out)
      .onComplete(()=>{
        opacityTween.stop();
      })
      .start();

    // const positionTween = new TWEEN.Tween(this._viewGroup.position)
    //   .to({ y: 0.2 }, duration)
    //   .easing(TWEEN.Easing.Sinusoidal.Out)
    //   .start();

    // return { opacityTween };
  }
  _showHideAnimation() {
    const opacityObject = { value: 0.8 };
    const duration = 0.5;

    const opacityTween = new TWEEN.Tween(opacityObject)
      .to({ value: 0 }, duration)
      .easing(TWEEN.Easing.Sinusoidal.Out)
      .start()
      .onUpdate(() => {
        this._view.material.opacity = opacityObject.value;
      });

    const positionTween = new TWEEN.Tween(this._viewGroup.position)
      .to({ y: 1.7 }, duration)
      .easing(TWEEN.Easing.Sinusoidal.Out)
      .start();

    const inclineTween = new TWEEN.Tween(this._view.rotation)
      .to({ x: 0 }, duration)
      .easing(TWEEN.Easing.Sinusoidal.Out)
      .start();

    return { opacityTween, positionTween, inclineTween };
  }
  _startLifeTimer() {
    const lifeTime = 20000;

    this._lifeTimer = new TWEEN.Tween({ value: 0 })
      .to({ value: 1 }, lifeTime)
      .start()
      .onComplete(() => {
        if (GLOBAL_VARIABLES.gameState === GAME_STATE.Gameplay) {
          this.kill();
        }
      });
  }
  _updateBulletMap(newPosition) {
    const bulletMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Bullet];
    // console.log(ghostMap,'ghostMap',this,'--this')
    if (this._currentPosition) {
      this._removeBulletFromArray(
        bulletMap[this._currentPosition.row][this._currentPosition.column]
      );
    }
    // console.log(
    //   this,
    //   bulletMap[newPosition.row][newPosition.column],
    //   newPosition
    // );
    bulletMap[newPosition.row][newPosition.column].push(this);
  }
  _removeBulletFromArray(array) {
    const index = array.indexOf(this);

    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  _updateDirther(obj) {
    if (obj.userData.threshold) {
      // gsap.to(obj.userData.threshold, {
      //   value: 1.0,
      //   duration: 10,
      //   onComplete: () => {
      //     console.log(obj);
      //   },
      // });
      const opacityTween = new TWEEN.Tween(obj.userData.threshold)
      .to({ value: 1.0 }, 10000)
      .easing(TWEEN.Easing.Sinusoidal.Out)
      .onComplete(()=>{

      })
      .start();

    }
  }

  _dirther(obj) {
    obj.userData.threshold = { value: 0.0 };
    obj.material.onBeforeCompile = (shader) => {
      shader.uniforms.threshold = obj.userData.threshold;
      shader.uniforms.edgeColor = { value: new THREE.Color(0xd27b00) };
      (shader.uniforms.edgeWidth = { value: 0.02 }),
        (shader.uniforms.noiseTex = {
          value: this.textureLoader.load("/textures/noise-2.png"),
        });
      const vertex = `
        varying vec2 uUv;
        void main(){
          uUv=uv;
        `;
      const fragment = `
        uniform float threshold;
        uniform sampler2D noiseTex;
        // uniform float edgeWidth;
        // uniform vec3 edgeColor;

        varying vec2 uUv;
        void main(){
        vec4 noiseValue = texture2D(noiseTex,uUv);

        if(noiseValue.r < threshold){
          discard;
        }
        // if(noiseValue.r - edgeWidth < threshold){
        //   color = vec4(edgeColor, 1.0);
        // }
      `;

      shader.vertexShader = shader.vertexShader.replace(
        "void main() {",
        vertex
      );
      shader.fragmentShader = shader.fragmentShader.replace(
        "void main() {",
        fragment
      );
    };
    // console.log(obj);
  }

  _initBullte() {
    const viewGroup = (this._viewGroup = new THREE.Group());
    // viewGroup.add(this._view.clone());
    this.add(viewGroup);
    const geometry = new THREE.SphereGeometry(0.11, 32, 16);
    const material = new THREE.MeshStandardMaterial({ color: 0x00ff00 });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.y = 0.7;
    mesh.castShadow=true;
    this._view = mesh.clone();
    // this._dirther(this._view);
    // this._updateDirther(this._view);
    viewGroup.add(this._view);
    this.hide();
  }

  setPosition(position) {
    this._currentPosition = position;

    const cellSize = GAME_CONFIG.cellSize;
    const currentLevel = GLOBAL_VARIABLES.currentLevel;
    const fieldConfig = LEVEL_CONFIG[currentLevel].field;
    this._viewGroup.position.x =-fieldConfig.columns * cellSize * 0.5 +cellSize * 0.5 +this._currentPosition.column * cellSize;
    this._viewGroup.position.z =-fieldConfig.rows * cellSize * 0.5 +cellSize * 0.5 +this._currentPosition.row * cellSize;
    this.show();
  }
  show() {
    this.visible = true;
  }
  hide() {
    this.visible = false;
  }
}
