import * as THREE from 'three';
import TWEEN from 'three/addons/libs/tween.module.js';
import PlayerPea from './player/player-pea';
import EnemiesController from './enemies/enemies-controller';
import { BUTTONS_CONFIG, BUTTON_TYPE } from './data/keyboard-config';
import ObstaclesController from './obstacles/obstacles-controller';
import { LEVEL_CONFIG, LEVEL_TYPE } from './data/level-config';
import { PLAYER_ACTIONS, PLAYER_ACTION_TO_DIRECTION, PLAYER_CONVERT_JUMP_IN_PLACE } from './player/data/player-data';

import { GAME_OBJECT_TYPE, GAME_STATE, MAP_TYPE } from './data/game-data';
import { GAME_CONFIG, ROUND_CONFIG } from './data/game-config';
import { MessageDispatcher } from 'black-engine';
import { SCORE_CONFIG } from './data/score-config';
import ConsumablesController from './consumables/consumables-controller';
import { GLOBAL_VARIABLES } from './data/global-variables';
import { vector3ToBlackPosition } from '../../../core/helpers/helpers';
import { CONSUMABLES_CONFIG, CONSUMABLE_TYPE } from './consumables/data/consumables-config';


import DEBUG_CONFIG from '../../../core/configs/debug-config';
import { SOUNDS_CONFIG } from '../../../core/configs/sounds-config';
import Loader from '../../../core/loader';
import Delayed from '../../../core/helpers/delayed-call';

export default class GameField extends THREE.Group {
  constructor(renderer, camera, audioListener,mixer) {
    super();

    this.events = new MessageDispatcher();

    this._renderer = renderer;
    this._camera = camera;
    this._audioListener = audioListener;
    this._mixer=mixer;

    this._player = null;
    this._enemiesController = null;//僵尸总控制
    this._obstaclesController = null;//墓碑总控制
    this._consumablesController = null;//补给总控制
    this._bossesController = null;


    this._playerActions = null;

    this._score = 0;
    this._previousGameTime = 0;
    this._gameTime = 0;
    this._roundTime = 0;
    this._isTutorialShown = false;

    this._globalVolume = SOUNDS_CONFIG.masterVolume;
    // this.ball=Loader.assets['ball'].scene.clone();
    this._init();
      // zpd新增球
      // this.progress = {value:10};//裂开范围
      // this.add(this.ball);
      // this._dirther(this.ball);
      // this._updateDirther(this.ball);
    
  }

  update(dt) {
    this._player.update(dt);
    this._enemiesController.update(dt);
    this._consumablesController.update(dt);
    this._updateGameTime(dt);
    this._updateRoundTime(dt);
    // this._bossesController.update(dt);
  }
  // zpd新增开始
  getCentroid = (geometry) => {
    const ar = geometry.attributes.position.array;
    const len = ar.length;
    let x = 0,
      y = 0,
      z = 0;
    for (let i = 0; i < len; i = i + 3) {
      x += ar[i];
      y += ar[i + 1];
      z += ar[i + 2];
    }
    return { x: (3 * x) / len, y: (3 * y) / len, z: (3 * z) / len };
  };
  
  _dirther(obj) {
    obj.userData.threshold = { value: 0.0 };
    obj.scale.setScalar(0.5);
    const material = new THREE.MeshStandardMaterial({ color: 0x00ff00 });
    let j = 0;
    obj.traverse((m) => {
      if (m instanceof THREE.Mesh) {
        j += 1;
        const len = m.geometry.attributes.position.array.length / 3;
        const offset = new Array(len).fill(j / 100);
        m.geometry.setAttribute(
          "offset",
          new THREE.BufferAttribute(new Float32Array(offset), 1)
        );

        const centroidVector = this.getCentroid(m.geometry);
        const centroid = new Array(len * 3).fill(0);
        for (let i = 0; i < len * 3; i = i + 3) {
          centroid[i] = centroidVector.x;
          centroid[i + 1] = centroidVector.y;
          centroid[i + 2] = centroidVector.z;
        }
        m.geometry.setAttribute(
          "centroids",
          new THREE.BufferAttribute(new Float32Array(centroid), 3)
        );

        m.material = material;
        m.material.onBeforeCompile = (shader) => {
          shader.uniforms.threshold = obj.userData.threshold;
          shader.uniforms.edgeColor = { value: new THREE.Color(0xd27b00) };
          shader.uniforms.progress = this.progress;
          (shader.uniforms.edgeWidth = { value: 0.02 }),
            (shader.uniforms.noiseTex = {
              value: Loader.assets["noise-2"],
            });
          const vertex = `
            varying vec2 uUv;
            uniform float progress;
            uniform float threshold;
            attribute vec3 centroids;
            attribute float offset;
            void main(){
              uUv=uv;
              vec3 newposition = position;
              float vTemp =  1. - ((centroids.x + centroids.y)*0.5 + 1.)/2.;
              float tProgress = max(0.0, (progress*threshold - vTemp*0.4) /0.6);
              newposition -= centroids*(tProgress)*(3. + offset*7.);
            `;
          const fragment = `
            uniform float threshold;
            uniform sampler2D noiseTex;
            // uniform float edgeWidth;
            // uniform vec3 edgeColor;
    
            varying vec2 uUv;
            void main(){
            vec4 noiseValue = texture2D(noiseTex,uUv);
    
            if(noiseValue.r < threshold){
              discard;
            }
            // if(noiseValue.r - edgeWidth < threshold){
            //   color = vec4(edgeColor, 1.0);
            // }
          `;
          const vertexPosition = `
            vec3 transformed = newposition;
            `;
          shader.vertexShader = shader.vertexShader.replace(
            "void main() {",
            vertex
          );
          shader.fragmentShader = shader.fragmentShader.replace(
            "void main() {",
            fragment
          );
          shader.vertexShader = shader.vertexShader.replace(
            "#include <begin_vertex>",
            vertexPosition
          );
        };
      }
    });
  }

  _updateDirther(obj) {
    if (obj.userData.threshold) {
      const opacityTween = new TWEEN.Tween(obj.userData.threshold)
        .to({ value: 1.0 }, 5000)
        .easing(TWEEN.Easing.Sinusoidal.Out)
        .onComplete(() => {})
        .start();
    }
  }
  // zpd新增结束
  initLevel(level) {
    this._resetLevel();

    GLOBAL_VARIABLES.gameState = GAME_STATE.Idle;
    GLOBAL_VARIABLES.currentLevel = level;
    GLOBAL_VARIABLES.round = 0;
    this.events.post('roundUp');
    this.events.post('initLevel');
    this.events.post('livesChanged');

    this._initPlayerForLevel();

    this._initMaps();
    this._obstaclesController.createObstacles();

    // this._bossesController.spawnBosses();
  }

  startGame() {
    this._obstaclesController.showIntro().on('complete', () => {//先实例化墓碑 再添加玩家
      this._player.spawn();
    });
  }

  restartGame() {
    this.initLevel(LEVEL_TYPE.Level001);// 需要传入当前 游戏等级
    this.startGame();
  }

  onHelpersChanged() {// 辅助方法 没用

  }

  onRoundChanged() {
    this._player.onRoundChanged();
    this._enemiesController.onRoundChanged();
  }

  onButtonPressed(buttonType) {
    if (GLOBAL_VARIABLES.gameState !== GAME_STATE.Gameplay) {
      return;
    }

    this._onButtonPress(buttonType);
  }

  onSoundChanged() {
    const collectVolume = SOUNDS_CONFIG.enabled ? SOUNDS_CONFIG.masterVolume * SOUNDS_CONFIG.collectSoundVolume : 0;
    this._collectSound.setVolume(collectVolume);

    const gameOverVolume = SOUNDS_CONFIG.enabled ? SOUNDS_CONFIG.masterVolume * SOUNDS_CONFIG.gameOverSoundVolume : 0;
    this._gameOverSound.setVolume(gameOverVolume);

    this._player.onSoundChanged();
    this._obstaclesController.onSoundChanged();
  }


  
  

  _startGameplay() {// 开始 初始化
    GLOBAL_VARIABLES.gameState = GAME_STATE.Gameplay;
    this._enemiesController.activateSpawnEnemies();
    this._consumablesController.activateSpawnConsumables();
    this.events.post('gameplayStarted');
  }

  _roundUp() {
    if (GLOBAL_VARIABLES.round >= ROUND_CONFIG.maxRound) {
      return;
    }
    
    GLOBAL_VARIABLES.round++;
    this.events.post('roundUp');
  }

  _initPlayerForLevel() {
    const playerConfig = LEVEL_CONFIG[GLOBAL_VARIABLES.currentLevel].player;
    this._player.setPosition(playerConfig.startPosition);
    this._player.setDirection(playerConfig.direction);


  }

  _resetLevel() {
    this._player.hide();
    this._player.reset();
    this._enemiesController.reset();
    this._obstaclesController.reset();
    this._consumablesController.reset();

    GLOBAL_VARIABLES.boosterSpawned = false;
    GLOBAL_VARIABLES.activeBooster = null;
    GLOBAL_VARIABLES.playerLives = 3;
    this._roundTime = 0;
    this._resetGameTime();
    this._setScore(0);
  }

  _updateGameTime(dt) {
    if (GLOBAL_VARIABLES.gameState === GAME_STATE.Gameplay) {
      this._gameTime += dt;

      if (this._gameTime - this._previousGameTime > 1) {
        this._previousGameTime = this._gameTime;
        const round = GLOBAL_VARIABLES.round;
        const score = SCORE_CONFIG.perSecond[round];
        
        this._addScore(score);
      }
    }
  }

  _updateRoundTime(dt) {
    if (GLOBAL_VARIABLES.gameState === GAME_STATE.Gameplay) {//游戏进行中
      this._roundTime += dt * 1000;

      if (this._roundTime > ROUND_CONFIG.roundDuration) {
        this._roundTime = 0;
        this._roundUp();
      }
    }
  }

  _addScore(score) {
    this._score += score;
    this.events.post('scoreChanged', this._score);
  }

  _setScore(score) {
    this._score = score;
    this.events.post('scoreChanged', this._score);
  }

  _resetGameTime() {
    this._previousGameTime = 0;
    this._gameTime = 0;
  }

  _playSound(sound) {
    if (sound.isPlaying) {
      sound.stop();
    }

    sound.play();
  }

  _init() {
    this._initPlayer();// 初始化 玩家
    this._initEnemiesController();

    this._initObstaclesController();
    this._initConsumablesController();


    this._initKeyboardEvents();
    this._initCollectSound();
    this._initGameOverSound();
    this._initZombieDeathSound();

    this._initSignals();
  }

  _initPlayer() {
    const player = this._player = new PlayerPea(this._audioListener);

    this.add(player);

    this._playerActions = {
      [BUTTON_TYPE.Left]: PLAYER_ACTIONS.JumpLeft,
      [BUTTON_TYPE.Right]: PLAYER_ACTIONS.JumpRight,
      [BUTTON_TYPE.Up]: PLAYER_ACTIONS.JumpUp,
      [BUTTON_TYPE.Down]: PLAYER_ACTIONS.JumpDown,
      [BUTTON_TYPE.Jump]: PLAYER_ACTIONS.JumpInPlace,
    };

   
  }

  _initEnemiesController() {//敌人
    const enemiesController = this._enemiesController = new EnemiesController(this._mixer);
    this.add(enemiesController);
  }

  _initObstaclesController() {// 墓碑
    const obstaclesController = this._obstaclesController = new ObstaclesController(this._audioListener);
    this.add(obstaclesController);
  }

  _initConsumablesController() {// 糖果
    const consumablesController = this._consumablesController = new ConsumablesController();
    this.add(consumablesController);
  }

  _initPlayerControoller(){// 初始化 玩家

  }



  _initMaps() {
    const currentLevel = GLOBAL_VARIABLES.currentLevel;
    const fieldConfig = LEVEL_CONFIG[currentLevel].field;
    const ghostMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Ghost] = [];
    const obstacleMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Obstacle] = [];
    const consumableMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Consumable] = [];
    const evilPumpkinMap = GLOBAL_VARIABLES.maps[MAP_TYPE.EvilPumpkin] = [];
    const skeletonMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Skeleton] = [];
    const bulletMap = GLOBAL_VARIABLES.maps[MAP_TYPE.bulletMap] = [];
    for (let row = 0; row < fieldConfig.rows; row++) {
      obstacleMap.push([]);//墓碑
      ghostMap.push([]);// 僵尸1
      consumableMap.push([]);//奖励品
      evilPumpkinMap.push([]);//僵尸2
      skeletonMap.push([]);//僵尸3
      bulletMap.push([])
      for (let column = 0; column < fieldConfig.columns; column++) {
        obstacleMap[row].push(null);
        consumableMap[row].push(null);
        ghostMap[row].push([]);
        evilPumpkinMap[row].push(null);
        skeletonMap[row].push(null);
        bulletMap[row].push([])
      }
    }
    // console.log(GLOBAL_VARIABLES.maps[MAP_TYPE.bulletMap] ,'---GLOBAL_VARIABLES.maps[MAP_TYPE.Skeleton] ')
  }



  _initCollectSound() {
    const collectSound = this._collectSound = new THREE.PositionalAudio(this._audioListener);
    this.add(collectSound);

    collectSound.setRefDistance(10);
    collectSound.setVolume(this._globalVolume * SOUNDS_CONFIG.collectSoundVolume);
    this.add(collectSound);

    Loader.events.on('onAudioLoaded', () => {
      collectSound.setBuffer(Loader.assets['collect']);
    });
  }

  _initGameOverSound() {
    const gameOverSound = this._gameOverSound = new THREE.PositionalAudio(this._audioListener);
    this.add(gameOverSound);

    gameOverSound.setRefDistance(10);
    gameOverSound.setVolume(this._globalVolume * SOUNDS_CONFIG.gameOverSoundVolume);
    this.add(gameOverSound);

    Loader.events.on('onAudioLoaded', () => {
      gameOverSound.setBuffer(Loader.assets['game-over']);
    });
  }
  _initZombieDeathSound(){
    const _zombieDeathSound = this._zombieDeathSound = new THREE.PositionalAudio(this._audioListener);
    this.add(_zombieDeathSound);

    _zombieDeathSound.setRefDistance(100);
    _zombieDeathSound.setVolume(this._globalVolume * SOUNDS_CONFIG.gameOverSoundVolume);
    this.add(_zombieDeathSound);

    Loader.events.on('onAudioLoaded', () => {
      _zombieDeathSound.setBuffer(Loader.assets['zDeath']);
    });
  }

  _initKeyboardEvents() {
    this._onPressDownSignal = this._onPressDownSignal.bind(this);

    window.addEventListener("keydown", this._onPressDownSignal);
  }

  _onPressDownSignal(e) {
    if (GLOBAL_VARIABLES.gameState !== GAME_STATE.Gameplay) {
      return;
    }

    for (const value in BUTTON_TYPE) {
      const buttonType = BUTTON_TYPE[value];
      const config = BUTTONS_CONFIG[buttonType];

      if (config.keyCode && config.keyCode.includes(e.code)) {
        this._onButtonPress(buttonType);
      }
    }
  }

  _onButtonPress(buttonType) {
    if (!this._isTutorialShown) {
      this._isTutorialShown = true;
      this.events.post('onButtonPress');
    }

    const action = this._playerActions[buttonType];

    if (action === PLAYER_ACTIONS.JumpInPlace) {
      // this._player.startAction(action);
      this._player.onShootEnemy();
      return;
    }

    const isJumpValid = this._checkPlayerJumpValidity(action);
    const newAction = isJumpValid ? action : PLAYER_CONVERT_JUMP_IN_PLACE[action];

    this._player.startAction(newAction);
  }

  _checkPlayerJumpValidity(action) {
    const currentLevel = GLOBAL_VARIABLES.currentLevel;
    const fieldConfig = LEVEL_CONFIG[currentLevel].field;
    const direction = PLAYER_ACTION_TO_DIRECTION[action];
    const newPosition = this._player.getNewPosition(direction);

    if (newPosition.row < 0 || newPosition.row >= fieldConfig.rows || newPosition.column < 0 || newPosition.column >= fieldConfig.columns) {
      return false;
    }

    const obstacleMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Obstacle];

    if (obstacleMap[newPosition.row][newPosition.column]) {
      return false;
    }

    return true;
  }

  _onLoseLive() {
    if (GLOBAL_VARIABLES.gameState === GAME_STATE.GameOver) {
      return;
    }

    GLOBAL_VARIABLES.playerLives--;
    this.events.post('livesChanged');

    if (GLOBAL_VARIABLES.playerLives <= 0) {// 生命结束
      this._onLose();// 
      return;
    }

    if (GLOBAL_VARIABLES.activeBooster) {
      this.events.post('stopBooster');
      GLOBAL_VARIABLES.activeBooster = null;
      this._enemiesController.stopEnemiesSlowBooster();
    }

    this._player.onLoseLive();
  }

  _onLose() {
    if (GLOBAL_VARIABLES.gameState === GAME_STATE.GameOver) {
      return;
    }
    
    GLOBAL_VARIABLES.gameState = GAME_STATE.GameOver;
    this._enemiesController.stopTweens();
    this._consumablesController.stopTweens();
    this._player.onKill();
    this.events.post('focusCameraOnPlayer');

    Delayed.call(700, () => {
      this._playSound(this._gameOverSound);
    });
  }

  _initSignals() {
    this._player.events.on('positionChanged', () => this._onPlayerPositionChanged());
    this._player.events.on('introFinished', () => this._startGameplay());
    this._player.events.on('onKill', () => this._onPlayerKill());
    const _this=this;
    this._player.events.on('startInvulnerabilityBooster', (msg, duration) => {//重新开始 角色保护时间

      _this.events.post('startInvulnerabilityBooster', duration)
    });
    this._enemiesController.events.on('positionChanged', () => this._onEnemyPositionChanged());
  }

  _onPlayerKill() {
    this.events.post('gameOver');
  }

  _onPlayerPositionChanged() {
    // console.log(GLOBAL_VARIABLES.gameState,GAME_STATE.Gameplay)
    if (GLOBAL_VARIABLES.gameState !== GAME_STATE.Gameplay) {//游戏暂停
      return;
    }

    // const playerPosition = GLOBAL_VARIABLES.playerPosition;


    this._checkConsumablesCollide();

    if (!DEBUG_CONFIG.invulnerability && this._player.isBodyActive()) {
      this._checkGhostCollide();
      this._checkEvilPumpkinCollide();
    }


   
  }

  _checkGhostCollide() {// 
    if (!this._player.isBodyActive()) {
      return;
    }

    const playerPosition = GLOBAL_VARIABLES.playerPosition;
    const ghostMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Ghost];

    if (ghostMap[playerPosition.row][playerPosition.column] && ghostMap[playerPosition.row][playerPosition.column].length > 0) {
      this._onLoseLive();
      return;
    }
  }

  _checkEvilPumpkinCollide() {//角色与 僵尸碰撞 
    if (!this._player.isBodyActive()) {
      return;
    }
    
    const playerPosition = GLOBAL_VARIABLES.playerPosition;
    const evilPumpkinMap = GLOBAL_VARIABLES.maps[MAP_TYPE.EvilPumpkin];
    const SkeletonMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Skeleton];

    if (evilPumpkinMap[playerPosition.row][playerPosition.column]||SkeletonMap[playerPosition.row][playerPosition.column]) {
      // 
  
      this._onLoseLive();//角色阵亡
      return;
    }

  }


  onKillEnemy(){
    if (!this._player.isBodyActive()) {
      return;
    }
    const bulletPosition = this._player._bullets;//改成子弹的位置
    const evilPumpkinMap = GLOBAL_VARIABLES.maps[MAP_TYPE.EvilPumpkin];
    const SkeletonMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Skeleton];

    bulletPosition.forEach(item=>{
      const bullet=item._currentPosition
      // console.log(this._enemiesController._activeEnemies['SKELETON'])
      if (evilPumpkinMap[bullet.row][bullet.column]){//
        const enmy= evilPumpkinMap[bullet.row][bullet.column]
   
        this.onSwitchAnim('walk','death',enmy)
        
        setTimeout(function(){
          enmy.kill()
  
        },600)

        this._playSound(this._zombieDeathSound)
      }
      if(SkeletonMap[bullet.row][bullet.column]){
        const enmy= SkeletonMap[bullet.row][bullet.column];
        
        this.onSwitchAnim('walk','death',enmy)
        this._playSound(this._zombieDeathSound)
        setTimeout(function(){
          enmy.kill()
        },1500)
       }
    
   

    })
    
  
    
  }
  onSwitchAnim(oldName,newName,mesh){

    const oldAnim=mesh._animations[oldName],newAnim=mesh._animations[newName];
    const idelAction = this._mixer.clipAction(oldAnim, mesh._view);
    // console.log(oldAnim,mesh._animations,idelAction,mesh._view)
    idelAction.stop();
    const newAciton=this._mixer.clipAction(newAnim, mesh._view);
    // mesh._setWeight(newAnim,1,0.4)
    newAciton.timeScale=0.5;
    newAciton.play();
    // action.setEffectiveTimeScale(1)
  }

  _checkConsumablesCollide() {// 身份切换
   
    const playerPosition = GLOBAL_VARIABLES.playerPosition;//角色当前位置
    const consumablesMap = GLOBAL_VARIABLES.maps[MAP_TYPE.Consumable];//获取 地图
    const consumable = consumablesMap[playerPosition.row][playerPosition.column];
   
    if (consumable) {
      const consumableType = consumable.getType();
      // console.log(consumableType,'---consumableType')
      const round = GLOBAL_VARIABLES.round;
      const score = SCORE_CONFIG.consumables[consumableType][round];

      // 修改玩家类型
      const coordinates = consumable.getCoordinates();
      const consumablePosition = new THREE.Vector3(coordinates.x, 0.7, coordinates.z);
      const screenPosition = vector3ToBlackPosition(consumablePosition, this._renderer, this._camera);
      this._addScore(score);
      this.events.post('onConsumableCollect', consumableType, screenPosition);

      this._collectSound.position.copy(consumablePosition);
      this._playSound(this._collectSound);


      switch(consumableType){
        case CONSUMABLE_TYPE.BoosterCandyEnemiesSlow:
        case CONSUMABLE_TYPE.BoosterCandyPlayerInvulnerability:
        case CONSUMABLE_TYPE.BoosterCandyPlayerSpeed:   
          this._startBooster(consumableType);
        break;
      }
      this._consumablesController.removeConsumable(consumable, false);//移除对象
    }
  }

  _startBooster(type) {//减缓速度
    GLOBAL_VARIABLES.activeBooster = type;
    GLOBAL_VARIABLES.boosterSpawned = false;
    
    if (type === CONSUMABLE_TYPE.BoosterCandyEnemiesSlow) {
      this._enemiesController.startEnemiesSlowBooster();// 
    }

    if (type === CONSUMABLE_TYPE.BoosterCandyPlayerSpeed) {
      this._player.startSpeedBooster();
    }

    if (type === CONSUMABLE_TYPE.BoosterCandyPlayerInvulnerability) {
      const boosterConfig = CONSUMABLES_CONFIG[CONSUMABLE_TYPE.BoosterCandyPlayerInvulnerability];
      this._player.startInvulnerabilityBooster(boosterConfig.duration);
    }
  }

  _onEnemyPositionChanged() {
    if (!DEBUG_CONFIG.invulnerability && this._player.isBodyActive()) {
      this._checkGhostCollide();
      this._checkEvilPumpkinCollide();
      this.onKillEnemy()
    }
  }
}
