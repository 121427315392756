import { Black, DisplayObject, Sprite } from "black-engine";

export default class DPad extends DisplayObject {
  constructor() {
    super();

    this.touchable = true;

  }

  onAdded() {
    this._init();
  }

  _init() {
    const offset = 70;

    const arrowLeft = new Sprite('arrow');
    this.add(arrowLeft);
    arrowLeft.alignPivot();
    arrowLeft.rotation = Math.PI / 2;
    arrowLeft.x = -offset;
    arrowLeft.y=offset*2-20;
    arrowLeft.touchable = true;

    const arrowRight = new Sprite('arrow');
    this.add(arrowRight);
    arrowRight.alignPivot();
    arrowRight.rotation = -Math.PI / 2;
    arrowRight.x = offset;
    arrowRight.y=offset*2-20;
    arrowRight.touchable = true;

    const arrowUp = new Sprite('arrow');
    this.add(arrowUp);
    arrowUp.alignPivot();
    arrowUp.rotation = Math.PI;

    arrowUp.y = offset*1-20;
    arrowUp.touchable = true;

    const arrowDown = new Sprite('arrow');
    this.add(arrowDown);
    arrowDown.alignPivot();
 
    arrowDown.y =  offset*3-20;
    arrowDown.touchable = true;

    const jump = new Sprite('shoot');
    this.add(jump);
    jump.alignPivot();
    jump.y =120;

    jump.touchable = true;
    jump.on('pointerDown', () => this.post('onJump'));

    arrowLeft.on('pointerDown', () => this.post('onLeft'));
    arrowRight.on('pointerDown', () => this.post('onRight'));
    arrowUp.on('pointerDown', () => this.post('onUp'));
    arrowDown.on('pointerDown', () => this.post('onDown'));
  }
}
