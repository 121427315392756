const DEBUG_CONFIG = {


  orbitControls: false,

  startFromGameplay: false,
  invulnerability: false,
  showControlPanel: false,
};
// const DEBUG_CONFIG = {
//   fpsMeter: true,
//   rendererStats: true,
//   orbitControls: true,
//  // axesHelper: true,
//   startFromGameplay: true,
//   invulnerability: true,
//   showControlPanel: true,
// };
export default DEBUG_CONFIG;
