import { CONSUMABLE_TYPE } from "../consumables/data/consumables-config";

const SCORE_CONFIG = {
  perSecond: [10, 20, 30, 40, 50,60,60,70,70,80,90,100],
  consumables: {
    [CONSUMABLE_TYPE.SmallCandy]: [5, 5, 10, 10,15,15,20,20,25,25],
    [CONSUMABLE_TYPE.BigCandy]: [10,10, 20, 20, 30, 30, 35, 35, 40, 40, 50, 50],
    [CONSUMABLE_TYPE.BoosterCandyPlayerSpeed]: [1000, 1500, 2000, 2500, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000],
    [CONSUMABLE_TYPE.BoosterCandyPlayerInvulnerability]: [1000, 1500, 2000, 2500, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 300],
    [CONSUMABLE_TYPE.BoosterCandyEnemiesSlow]: [1000, 1500, 2000, 2500, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 300],
  },
}

export { SCORE_CONFIG };
