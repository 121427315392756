import * as THREE from 'three';
import TWEEN from 'three/addons/libs/tween.module.js';
import { MessageDispatcher } from 'black-engine';
import { PLAYER_ACTIONS, PLAYER_JUMP_STATE, PLAYER_STATE } from './data/player-data';
import { PLAYER_CONFIG } from './data/player-config';
import { LEVEL_CONFIG } from '../data/level-config';
import { DIRECTION, GAME_STATE, ROTATION_BY_DIRECTION } from '../data/game-data';
import { GAME_CONFIG, ROUND_CONFIG } from '../data/game-config';
import { GLOBAL_VARIABLES } from '../data/global-variables';
import { getCoordinatesFromPosition } from '../../../../core/helpers/helpers';
import { CONSUMABLES_CONFIG, CONSUMABLE_TYPE } from '../consumables/data/consumables-config';
import Loader from '../../../../core/loader';
import Delayed from '../../../../core/helpers/delayed-call';
import SCENE_CONFIG from '../../../../core/configs/scene-config';
import { SOUNDS_CONFIG } from '../../../../core/configs/sounds-config';
import peaBullet from './pea-bullet'

export default class PlayerPea extends THREE.Group {//豌豆
  constructor(audioListener) {
    super();

    this.events = new MessageDispatcher();//事件传递

    this._audioListener = audioListener;

    this._viewGroup = null;

    this._grave = null;// 游戏结束时的墓碑
    this._ghostView = null;//角色阵亡后 飘浮灵魂
    this._currentPosition = { row: 0, column: 0 };//角色当前位置
    this._newPosition = { row: 0, column: 0 };//角色最新位置
    this._jumpSpeed = 0;//角色跳跃高度速度
    this._jumpHalfTime = null;
    this._goingUpTween = null;
    this._goingDownTween = null;
    this._speedBoosterTimer = null;
    this._invulnerabilityBoosterTimer = null;
    this._idleSqueezeTweens = { scaleTween: null, positionTween: null };
    this._beforeJumpSqueezeTweens = { scaleTween: null, positionTween: null };
    this._afterJumpSqueezeTweens = { scaleTween: null, positionTween: null };
    this._rotationTween = null;
    this._moveToPositionTween = null;
    this._squeezeTop = 1 + PLAYER_CONFIG.jumpAnimation.squeezePower * PLAYER_CONFIG.jumpImpulse;
    this._squeezeSides = 1 - PLAYER_CONFIG.jumpAnimation.squeezePower * PLAYER_CONFIG.jumpImpulse;

    this._state = PLAYER_STATE.Idle;
    this._jumpState = PLAYER_JUMP_STATE.None;
    this._previousJumpState = PLAYER_JUMP_STATE.None;

    this._actions = null;
    this._nextAction = null;
    this._isNextActionAllowed = false;

    this._isBodyActive = true;
    this._currentDirection = DIRECTION.Up;

    this._globalVolume = SOUNDS_CONFIG.masterVolume;
    
    this._bullets=[];
    this.count=0;
    this._init();
  }

  update(dt) {
    this._updateJump(dt);

    this._bullets.forEach(bullet=>{
      bullet.update(dt)
    })
    const position = this._calculateCurrentPosition();
    // this.pea.update();
    if (position.row !== this._currentPosition.row || position.column !== this._currentPosition.column) {//角色位置发生变化
      this.count=0;
      this._currentPosition = GLOBAL_VARIABLES.playerPosition = position;//更新 角色 位置
      this.events.post('positionChanged');//事件传递的方法

     
      
    }
    // else{
    //   // this.count+=1;
    //   // if(this.count>=120&&this._state==PLAYER_STATE.Idle){
    //   //   this.count=0;
    //   //   const bullet =new peaBullet();
    //   //   this.add(bullet);
    //   //   this._bullets.push( bullet)
    //   //   bullet._currentPosition=this._currentPosition;
    //   //   bullet._currentDirection=this._currentDirection
    //   //   bullet.spawnBullet()
    //   //   this._initEnemySignals(bullet)
    //   //   this._playSound(this._shootSound)
        
    //   // }
    // }
    
  }
  onShootEnemy(){
    const bullet =new peaBullet();
    this.add(bullet);
    this._bullets.push( bullet)
    bullet._currentPosition=this._currentPosition;
    bullet._currentDirection=this._currentDirection
    bullet.spawnBullet()
    this._initEnemySignals(bullet)
    this._playSound(this._shootSound)
  }
  _initEnemySignals(bullet) {//初始化坏人事件
    // bullet.events.on('positionChanged', () => this.events.post('positionChanged'));
    bullet.events.on('onKilled', (msg, enemyToKill) => this._removeBullet(enemyToKill));
  }
  _removeBullet(bullet) {
    // const type = enemy.getType();
    const index = this._findActiveEnemyIndex(bullet);
    this._bullets.splice(index, 1);
    // this._enemiesPool[type].push(enemy);
  }
  _findActiveEnemyIndex(bullet) {
    
    for (let i = 0; i < this._bullets.length; i++) {
      const activeEnemy = this._bullets[i];
      if (activeEnemy === bullet) {
        return i;
      }
    }
  }
  startAction(action) {
    if (this._state === PLAYER_STATE.DeathAnimationLoseLive) {
      return;
    }

    if (this._state !== PLAYER_STATE.Idle) {
      if (this._isNextActionAllowed) {
        // console.log(action,'---action')
        this._nextAction = action;
      }

      return;
    }

    this._resetJumpingTweens();
    this._isNextActionAllowed = false;
    this._nextAction = null;
    this._actions[action]();
  }

  getNewPosition(direction) {
    const newPosition = { row: this._currentPosition.row, column: this._currentPosition.column };

    switch (direction) {
      case DIRECTION.Up:
        newPosition.row--;
        break;

      case DIRECTION.Down:
        newPosition.row++;
        break;

      case DIRECTION.Left:
        newPosition.column--;
        break;

      case DIRECTION.Right:
        newPosition.column++;
        break;
    }

    return newPosition;
  }

  setPosition(position) {
    this._currentPosition = GLOBAL_VARIABLES.playerPosition = position;
    this._newPosition = position;

    const cellSize = GAME_CONFIG.cellSize;
    const currentLevel = GLOBAL_VARIABLES.currentLevel;
    const fieldConfig = LEVEL_CONFIG[currentLevel].field;
    this._viewGroup.position.x = (-fieldConfig.columns * cellSize * 0.5 + cellSize * 0.5) + this._currentPosition.column * cellSize;
    this._viewGroup.position.z = (-fieldConfig.rows * cellSize * 0.5 + cellSize * 0.5) + this._currentPosition.row * cellSize;
  }

  getPosition() {
    return this._currentPosition;
  }

  setDirection(direction) {
    this._currentDirection = direction;
    this._viewGroup.rotation.y = ROTATION_BY_DIRECTION[direction];
  }

  onRoundChanged() {
    if (GLOBAL_VARIABLES.activeBooster === CONSUMABLE_TYPE.BoosterCandyPlayerSpeed) {
      return;
    }

    const round = GLOBAL_VARIABLES.round;
    const playerRoundConfig = ROUND_CONFIG.player[round];
    console.log(GLOBAL_VARIABLES,round,ROUND_CONFIG.player,'--',ROUND_CONFIG.player[round])
    PLAYER_CONFIG.speedMultiplier = playerRoundConfig.speedMultiplier;
    this._updateJumpTime();
  }

  onKill() {
    this._state = PLAYER_STATE.DeathAnimationLoseGame;
    this._resetAllTweens();
    this._jumpSpeed = 0;
    this._nextAction = null;
    this._speedBoosterTimer?.stop();

    this._showDeathAnimation();
  }

  show() {//角色 玩家显示
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  startSpeedBooster() {
    const boosterConfig = CONSUMABLES_CONFIG[CONSUMABLE_TYPE.BoosterCandyPlayerSpeed];

    PLAYER_CONFIG.speedMultiplier = boosterConfig.speedMultiplier;
    this._updateJumpTime();

    this._speedBoosterTimer = new TWEEN.Tween({ value: 0 })
      .to({ value: 1 }, boosterConfig.duration)
      .start()
      .onComplete(() => {
        GLOBAL_VARIABLES.activeBooster = null;
        this.onRoundChanged();
      });
  }

  startInvulnerabilityBooster(duration) {////重新开始 角色保护时间

  
    this._invulnerabilityBoosterTimer?.stop();

    this._isBodyActive = false;


    this._innerCylinder.visible = false;


    const _this=this;
    setTimeout(() => {
      _this._changeMatOpact(this._view,1)
      this._innerCylinder.visible =true;
    }, 0.3);

 
    this._invulnerabilityBoosterTimer = new TWEEN.Tween({ value: 0 })
      .to({ value: 1 }, duration)
      .start()
      .onComplete(() => {
        GLOBAL_VARIABLES.activeBooster = null;
        this._isBodyActive = true;

   
        this._changeMatOpact(this._view,1)
        this._innerCylinder.visible = false;
      });
  }

  spawn(postEvent = true) {// 初始化 角色和开始游戏程序
    this._state = PLAYER_STATE.SpawnAnimation;

    this.show();//角色玩家显示
    const spawnPositionY = SCENE_CONFIG.isMobile ? PLAYER_CONFIG.spawnAnimation.mobile.positionY : PLAYER_CONFIG.spawnAnimation.desktop.positionY;
    const spawnDuration = SCENE_CONFIG.isMobile ? PLAYER_CONFIG.spawnAnimation.mobile.duration : PLAYER_CONFIG.spawnAnimation.desktop.duration;
    this._viewGroup.position.y = spawnPositionY;

    new TWEEN.Tween(this._viewGroup.position)
      .to({ y: PLAYER_CONFIG.halfHeight }, spawnDuration)
      .easing(TWEEN.Easing.Cubic.In)
      .start()
      .onComplete(() => {
        this._playSound(this._jumpSound);
        const squeezeTween = this._squeezeOnGround(0.6, 50, TWEEN.Easing.Sinusoidal.Out);
        squeezeTween.positionTween?.onComplete(() => {
          this._state = PLAYER_STATE.Idle;
          this._startIdleAnimation(true);//播放默认动画

          if (postEvent) {
            this.events.post('introFinished');
          }
        });
      });
  }



  isBodyActive() {
    return this._isBodyActive;
  }

  onSoundChanged() {
    const jumpVolume = SOUNDS_CONFIG.enabled ? SOUNDS_CONFIG.masterVolume * SOUNDS_CONFIG.jumpSoundVolume : 0;
    this._jumpSound.setVolume(jumpVolume);

    const smashVolume = SOUNDS_CONFIG.enabled ? SOUNDS_CONFIG.masterVolume * SOUNDS_CONFIG.smashSoundVolume : 0;
    this._smashSound.setVolume(smashVolume);
    
    const deathVolume = SOUNDS_CONFIG.enabled ? SOUNDS_CONFIG.masterVolume * SOUNDS_CONFIG.deathSoundVolume : 0;
    this._deathSound.setVolume(deathVolume);
    const shootVolume = SOUNDS_CONFIG.enabled ? SOUNDS_CONFIG.masterVolume * SOUNDS_CONFIG.deathSoundVolume : 0;
    this._shootSound.setVolume(shootVolume);
  }

  onLoseLive() {// 死亡

    this._ghostView.position.y = this._viewGroup.position.y - PLAYER_CONFIG.halfHeight;
    this.reset();
    this._isBodyActive = false;
    this._state = PLAYER_STATE.DeathAnimationLoseLive;

    this._speedBoosterTimer?.stop();
    this._invulnerabilityBoosterTimer?.stop();

    this.onRoundChanged();

    this._playSound(this._deathSound);

    this._showLoseLiveAnimation().onComplete(() => {
      this._view.visible = true;
      this._innerCylinder.visible = true;
      this._ghostView.visible = false;

      const playerConfig = LEVEL_CONFIG[GLOBAL_VARIABLES.currentLevel].player;
      this.setPosition(playerConfig.startPosition);
      this.setDirection(playerConfig.direction);

      const playerInvulnerabilityDuration =PLAYER_CONFIG.invulnerabilityAfterDeathDuration;
      this.events.post('startInvulnerabilityBooster', playerInvulnerabilityDuration);
      this.startInvulnerabilityBooster(playerInvulnerabilityDuration);
      GLOBAL_VARIABLES.activeBooster = CONSUMABLE_TYPE.BoosterCandyPlayerInvulnerability;

      this.spawn(false);
    });
  }

  reset() {
    this._resetAllTweens();
    this._setJumpState(PLAYER_JUMP_STATE.None);
    this._state = PLAYER_STATE.Idle;
    this._currentDirection = DIRECTION.Up;
    this._viewGroup.scale.set(1, 1, 1);
    this._viewGroup.position.y = PLAYER_CONFIG.halfHeight;
    this._jumpSpeed = 0;
    this._nextAction = null;
    this._isNextActionAllowed = false;
    this._speedBoosterTimer?.stop();
    this._invulnerabilityBoosterTimer?.stop();
    this._isBodyActive = true;


    this._changeMatOpact(this._view,1)
    this._innerCylinder.visible = false;
    this._grave.visible = false;
    this._ghostView.visible = false;
  }

  _showLoseLiveAnimation() {
    const duration = 1000;

    this._view.visible = false;
    this._innerCylinder.visible = false;
    this._ghostView.visible = true;

    this._changeMatOpact(this._ghostView,0.5)
    const positionTween = new TWEEN.Tween(this._ghostView.position)
      .to({ y: this._ghostView.position.y + 1.5 }, duration)
      .easing(TWEEN.Easing.Sinusoidal.In)
      .start();

 
  
    this._chnageMatAnimOpact(this._ghostView,0,duration);// 角色死亡后 消失动画
    return positionTween;
  }

  _showDeathAnimation() {
    if (this._grave.visible === true) {
      return;
    }

    const spawnPositionY = SCENE_CONFIG.isMobile ? PLAYER_CONFIG.spawnAnimation.mobile.positionY : PLAYER_CONFIG.spawnAnimation.desktop.positionY;
    const spawnDuration = SCENE_CONFIG.isMobile ? PLAYER_CONFIG.spawnAnimation.mobile.duration : PLAYER_CONFIG.spawnAnimation.desktop.duration;
    this._grave.position.y = spawnPositionY;
    this._grave.position.x = this._viewGroup.position.x;
    this._grave.position.z = this._viewGroup.position.z;
    this._grave.visible = true;
    this._innerCylinder.visible = false;

    new TWEEN.Tween(this._grave.position)
      .to({ y: 0 }, spawnDuration)
      .easing(TWEEN.Easing.Cubic.In)
      .start();

    const squeezeDuration = 200;
    const squeezeDelay = spawnDuration - 200;

    Delayed.call(squeezeDelay, () => {
      this._playSound(this._smashSound);

      new TWEEN.Tween(this._viewGroup.position)
        .to({ y: 0.04 }, squeezeDuration)
        .easing(TWEEN.Easing.Sinusoidal.In)
        .start();

      new TWEEN.Tween(this._viewGroup.scale)
        .to({ y: 0.06, x: 1.6, z: 1.6 }, squeezeDuration)
        .easing(TWEEN.Easing.Sinusoidal.In)
        .start()
        .onComplete(() => {
          Delayed.call(800, () => {
            this.events.post('onKill');
          });
        });
    })
  }

  _calculateCurrentPosition() {
    const cellSize = GAME_CONFIG.cellSize;
    const currentLevel = GLOBAL_VARIABLES.currentLevel;
    const fieldConfig = LEVEL_CONFIG[currentLevel].field;
    const row = Math.round((this._viewGroup.position.z + fieldConfig.rows * cellSize * 0.5 - cellSize * 0.5) / cellSize);
    const column = Math.round((this._viewGroup.position.x + fieldConfig.columns * cellSize * 0.5 - cellSize * 0.5) / cellSize);

    return { row, column };
  }

  _updateJump(dt) {
    if (this._jumpState === PLAYER_JUMP_STATE.GoingUp || this._jumpState === PLAYER_JUMP_STATE.GoingDown) {
      this._viewGroup.position.y += this._jumpSpeed * dt * PLAYER_CONFIG.speedMultiplier;//跳跃高度
      
      this._jumpSpeed -= GAME_CONFIG.gravity * PLAYER_CONFIG.mass * dt * PLAYER_CONFIG.speedMultiplier;
      if (this._jumpSpeed < 0) {// 玩家 没有跳跃
        this._setJumpState(PLAYER_JUMP_STATE.GoingDown);
      }

      if (this._jumpState === PLAYER_JUMP_STATE.GoingDown && this._previousJumpState === PLAYER_JUMP_STATE.GoingUp) {
        this._resetJumpingTweens();

        if (GLOBAL_VARIABLES.gameState === GAME_STATE.Gameplay) {// 游戏进行状态
          this._goingDownTween = this._squeeze(this._squeezeTop, this._jumpHalfTime, TWEEN.Easing.Sinusoidal.In);
          this._isNextActionAllowed = true;
        }
      }

      if (this._jumpState === PLAYER_JUMP_STATE.GoingDown && this._viewGroup.position.y < PLAYER_CONFIG.halfHeight * this._squeezeTop) {
        this._viewGroup.position.y = PLAYER_CONFIG.halfHeight * this._squeezeTop;
        this._jumpSpeed = 0;

        if (this._state === PLAYER_STATE.DeathAnimationLoseGame) {
          this._jumpState = PLAYER_JUMP_STATE.None;
        }

        if (GLOBAL_VARIABLES.gameState === GAME_STATE.Gameplay) { // 游戏进行状态
          this._showAnimationAfterJump();
        }
      }
    }
  }

  _jumpByDirection(direction) {
    this._newPosition = this.getNewPosition(direction);

    this._rotateToDirection(direction);
    this._startJump();
  }

  _jumpInPlaceWithRotation(direction) {
    this._rotateToDirection(direction);
    this._startJump();
  }

  _jumpInPlace() {
  
    this._startJump();
  }

  _startJump() {//原地跳
    this._state = PLAYER_STATE.Jump;
    this._playSound(this._jumpSound);
    this._stopIdleAnimation();
    this._showAnimationBeforeJump();
  }

  _showAnimationBeforeJump() {
    this._setJumpState(PLAYER_JUMP_STATE.SqueezeBeforeJumpPhase01);

    const scaleDifference = (this._viewGroup.scale.y - this._squeezeSides) / (1 - this._squeezeSides);
    const squeezeDuration = PLAYER_CONFIG.jumpAnimation.squeezeDuration * scaleDifference / PLAYER_CONFIG.speedMultiplier;

    if (scaleDifference < PLAYER_CONFIG.jumpAnimation.disableAnimationBeforeJumpThreshold) {
      this._phase02BeforeJump();

      return;
    }

    this._beforeJumpSqueezeTweens = this._squeezeOnGround(this._squeezeSides, squeezeDuration * 0.25, TWEEN.Easing.Sinusoidal.In);
    this._beforeJumpSqueezeTweens.positionTween?.onComplete(() => {
      this._phase02BeforeJump();
    });
  }

  _phase02BeforeJump() {
    this._setJumpState(PLAYER_JUMP_STATE.SqueezeBeforeJumpPhase02);

    const duration = PLAYER_CONFIG.jumpAnimation.squeezeDuration * 0.5 / PLAYER_CONFIG.speedMultiplier;
    this._beforeJumpSqueezeTweens = this._squeezeOnGround(this._squeezeTop, duration * 0.25, TWEEN.Easing.Sinusoidal.In)
    this._beforeJumpSqueezeTweens.positionTween?.onComplete(() => {
      this._setJumpState(PLAYER_JUMP_STATE.GoingUp);
      this._jumpSpeed = PLAYER_CONFIG.jumpImpulse;

      this._goingUpTween = this._squeeze(1, this._jumpHalfTime, TWEEN.Easing.Sinusoidal.In);
      this._moveToPosition(this._newPosition);
    });
  }

  _showAnimationAfterJump() {//玩家跳跃完成之后 恢复默认动画状态

    this._resetJumpingTweens();//清楚所有跳跃相关 tween 数据

    this._setJumpState(PLAYER_JUMP_STATE.SqueezeAfterJump);

    const duration = PLAYER_CONFIG.jumpAnimation.squeezeDuration / PLAYER_CONFIG.speedMultiplier;
    this._afterJumpSqueezeTweens = this._squeezeOnGround(this._squeezeSides, duration, TWEEN.Easing.Sinusoidal.Out);
    this._afterJumpSqueezeTweens.positionTween?.onComplete(() => {
      if (this._nextAction) {// 如果有下一个跳跃动作 则继续跳跃
        this._setJumpState(PLAYER_JUMP_STATE.None);
        this._state = PLAYER_STATE.Idle;
        this.startAction(this._nextAction);

        return;
      }
      
      this._setJumpState(PLAYER_JUMP_STATE.None);
      this._state = PLAYER_STATE.Idle;// 玩家状态更新
      this._startIdleAnimation(true);
    });
  }

  _squeezeOnGround(squeezePower, duration, easing) {// 角色 大小 位置形变动画+
    const scaleTween = this._squeeze(squeezePower, duration, easing);

    const positionTween = new TWEEN.Tween(this._viewGroup.position)
      .to({ y: PLAYER_CONFIG.halfHeight * squeezePower }, duration)//  halfHeight: 0.3,
      .easing(easing)
      .start();

    return { scaleTween, positionTween };
  }

  _squeeze(squeezePower, duration, easing) {//大小形变
    const squeezeSides = (1 - squeezePower) + 1;

    const tween = new TWEEN.Tween(this._viewGroup.scale)
      .to({ y: squeezePower, x: squeezeSides, z: squeezeSides }, duration)
      .easing(easing)
      .start();

    return tween;
  }

  _moveToPosition(newPosition) {
    const coordinates = getCoordinatesFromPosition(newPosition);

    this._moveToPositionTween = new TWEEN.Tween(this._viewGroup.position)
      .to({ x: coordinates.x, z: coordinates.z }, this._jumpHalfTime * 2)
      .easing(TWEEN.Easing.Linear.None)
      .start();
  }

  _startIdleAnimation(startFromSecondPhase = false) {
    if (startFromSecondPhase) {
      this._playIdleAnimationPhase02();
    } else {
      const duration = PLAYER_CONFIG.idleAnimation.squeezeDuration / PLAYER_CONFIG.speedMultiplier;
      this._idleSqueezeTweens = this._squeezeOnGround(PLAYER_CONFIG.idleAnimation.squeezePower, duration, TWEEN.Easing.Sinusoidal.InOut);
      this._idleSqueezeTweens.positionTween?.onComplete(() => {
        this._playIdleAnimationPhase02();
      });
    }
  }

  _playIdleAnimationPhase02() {
    const duration = PLAYER_CONFIG.idleAnimation.squeezeDuration / PLAYER_CONFIG.speedMultiplier;
    this._idleSqueezeTweens = this._squeezeOnGround(1, duration, TWEEN.Easing.Sinusoidal.InOut);
    this._idleSqueezeTweens.positionTween?.onComplete(() => {
      this._startIdleAnimation();
    });
  }

  _rotateToDirection(direction) {
    if (this._currentDirection === direction) {
      return;
    }

    let targetAngle = ROTATION_BY_DIRECTION[direction];

    if (this._currentDirection === DIRECTION.Down && direction === DIRECTION.Left) {
      targetAngle = -Math.PI / 2;
    }

    if (this._currentDirection === DIRECTION.Left && direction === DIRECTION.Down) {
      targetAngle = Math.PI * 2;
    }

    this._currentDirection = direction;

    this._rotationTween = new TWEEN.Tween(this._viewGroup.rotation)
      .to({ y: targetAngle }, this._jumpHalfTime * 2)
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .start()
      .onComplete(() => {
        this._viewGroup.rotation.y = ROTATION_BY_DIRECTION[direction];
      });
  }

  _setJumpState(state) {
    this._previousJumpState = this._jumpState;
    this._jumpState = state;
  }

  _stopIdleAnimation() {// 停止默认动画
    this._idleSqueezeTweens.positionTween?.stop();
    this._idleSqueezeTweens.scaleTween?.stop();
  }

  _resetJumpingTweens() {
    this._goingUpTween?.stop();
    this._goingDownTween?.stop();
    this._beforeJumpSqueezeTweens.positionTween?.stop();
    this._beforeJumpSqueezeTweens.scaleTween?.stop();
    this._afterJumpSqueezeTweens.positionTween?.stop();
    this._afterJumpSqueezeTweens.scaleTween?.stop();
  }

  _resetAllTweens() {
    this._resetJumpingTweens();
    this._stopIdleAnimation();

    this._rotationTween?.stop();
    this._moveToPositionTween?.stop();
  }

  _init() {
    this._initView();// 初始化玩家模型
    this._initGrave();//初始化墓碑模型
    this._initGhostView();// 死后玩家模型
    this._updateJumpTime();//玩家跳的时间
    this._initActions();
    this._initSounds();


    this.hide();
  }

  _initView() {// 不需要动画
    const viewGroup = this._viewGroup = new THREE.Group();
    this.add(viewGroup);

    const view = this._view = Loader.assets['player-pea'].scene.clone();//Loader.assets['player-pumpkin'].scene.children[0].clone();
    view.position.y=-0.1;
    // view.position.set(0,-0.15,0)
    viewGroup.add(view);
    console.log(view,'--view')
    const scale = 0.6;
    view.scale.set(scale, scale, scale);
    view.traverse(child => {
      if (child.isMesh) {
        child.material.transparent=true
        child.castShadow = true;
      }
    });

   
    // const innerCylinderGeometry =;;// new THREE.CylinderGeometry(0.38, 0.38, 0.37, 32, 1, true);
    // const cylinderMaterial = new THREE.MeshBasicMaterial({ color: 0xffd700 });
    const cylinder = this._innerCylinder =Loader.assets['cover'].scene.clone();// new THREE.Mesh(innerCylinderGeometry, cylinderMaterial);
    cylinder.scale.set(2,2,2)
    viewGroup.add(cylinder);
    cylinder.visible=false;
    viewGroup.position.y = PLAYER_CONFIG.halfHeight;
    viewGroup.rotation.y = ROTATION_BY_DIRECTION[this._currentDirection];
  }

  _initGrave() {// 墓碑
    const grave = this._grave = Loader.assets['player-grave'].scene.children[0].clone();
    this.add(grave);

    const scale = 0.6;
    grave.scale.set(scale, scale, scale);

    const texture = Loader.assets['grave_basecolor'];
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;

    const material = new THREE.MeshStandardMaterial({
      map: texture,
    });

    grave.material = material;
    grave.visible = false;

    grave.castShadow = true;
  }

  _initGhostView() {// 死后的玩家
    const ghostView = this._ghostView =Loader.assets['player-pea'].scene.clone();// Loader.assets['player-pumpkin'].scene.children[0].clone();
    this._viewGroup.add(ghostView);

    const scale = 0.6;
    ghostView.scale.set(scale, scale, scale);

    const material = new THREE.MeshStandardMaterial({
      transparent: true,
      opacity: 1,
    });

    ghostView.material = material;
    ghostView.castShadow = true;

    ghostView.visible = false;
  }
  _changeMatOpact(mesh,float){
    mesh.traverse(child=>{
      if(child.material){
        child.material.transparent= true;
        child.material.opacity=float
      }
    })
  }
  _chnageMatAnimOpact(mesh,float,duration){
    mesh.traverse(child=>{
      if(child.material){
        new TWEEN.Tween(child.material)
          .to({ opacity: float }, duration)
          .easing(TWEEN.Easing.Sinusoidal.In)
          .start();
      }
    })
  }
  _updateJumpTime() {// 
    const jumpHeight = PLAYER_CONFIG.jumpImpulse * PLAYER_CONFIG.jumpImpulse / (2 * GAME_CONFIG.gravity * PLAYER_CONFIG.mass * PLAYER_CONFIG.mass);
    this._jumpHalfTime = Math.sqrt(2 * jumpHeight / GAME_CONFIG.gravity) * 1000 / PLAYER_CONFIG.speedMultiplier;
  }

  _initActions() {// 初始化动作
    this._actions = {
      [PLAYER_ACTIONS.JumpLeft]: () => this._jumpByDirection(DIRECTION.Left),
      [PLAYER_ACTIONS.JumpRight]: () => this._jumpByDirection(DIRECTION.Right),
      [PLAYER_ACTIONS.JumpUp]: () => this._jumpByDirection(DIRECTION.Up),
      [PLAYER_ACTIONS.JumpDown]: () => this._jumpByDirection(DIRECTION.Down),
      [PLAYER_ACTIONS.JumpInPlaceRotateLeft]: () => this._jumpInPlaceWithRotation(DIRECTION.Left),
      [PLAYER_ACTIONS.JumpInPlaceRotateRight]: () => this._jumpInPlaceWithRotation(DIRECTION.Right),
      [PLAYER_ACTIONS.JumpInPlaceRotateUp]: () => this._jumpInPlaceWithRotation(DIRECTION.Up),
      [PLAYER_ACTIONS.JumpInPlaceRotateDown]: () => this._jumpInPlaceWithRotation(DIRECTION.Down),
      [PLAYER_ACTIONS.JumpInPlace]: () => this._jumpInPlace(),
    }
  }

  _initSounds() {
    this._initJumpSound();
    this._initSmashSound();
    this._initDeathSound();
    this._initShootSound();
  }

  _initJumpSound() {
    const jumpSound = this._jumpSound = new THREE.PositionalAudio(this._audioListener);
    this.add(jumpSound);

    jumpSound.setRefDistance(100);
    jumpSound.position.copy(this._viewGroup.position);
    jumpSound.setVolume(this._globalVolume * SOUNDS_CONFIG.jumpSoundVolume);

    Loader.events.on('onAudioLoaded', () => {
      jumpSound.setBuffer(Loader.assets['jump']);
    });
  }

  _initSmashSound() {
    const smashSound = this._smashSound = new THREE.PositionalAudio(this._audioListener);
    this.add(smashSound);

    smashSound.setRefDistance(10);
    smashSound.position.copy(this._viewGroup.position);
    smashSound.setVolume(this._globalVolume * SOUNDS_CONFIG.smashSoundVolume);

    Loader.events.on('onAudioLoaded', () => {
      smashSound.setBuffer(Loader.assets['smash']);
    });
  }

  _initDeathSound() {
    const deathSound = this._deathSound = new THREE.PositionalAudio(this._audioListener);
    this.add(deathSound);

    deathSound.setRefDistance(10);
    deathSound.position.copy(this._viewGroup.position);
    deathSound.setVolume(this._globalVolume * SOUNDS_CONFIG.deathSoundVolume);

    Loader.events.on('onAudioLoaded', () => {
      deathSound.setBuffer(Loader.assets['death']);
    });
  }
  _initShootSound(){
    const shootSound = this._shootSound = new THREE.PositionalAudio(this._audioListener);
    this.add(shootSound);

    shootSound.setRefDistance(100);
    shootSound.position.copy(this._viewGroup.position);
    shootSound.setVolume(this._globalVolume * SOUNDS_CONFIG.deathSoundVolume);

    Loader.events.on('onAudioLoaded', () => {
      shootSound.setBuffer(Loader.assets['shoot']);
    });
  }
  
  _playSound(sound) {
    if (sound.isPlaying) {
      sound.stop();
    }

    sound.play();
  }




}
