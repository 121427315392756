import * as THREE from 'three';
import TWEEN from 'three/addons/libs/tween.module.js';
import SCENE_CONFIG from './configs/scene-config';
import MainScene from '../main-scene';
import LoadingOverlay from './loading-overlay';
import { CanvasDriver, Engine, Input, MasterAudio, StageScaleMode } from 'black-engine';
import Loader from './loader';
import Scene3DDebugMenu from './helpers/gui-helper/scene-3d-debug-menu';

import isMobile from 'ismobilejs';


import Stats from "three/examples/jsm/libs/stats.module.js";
export default class BaseScene {
  constructor() {
    this._scene = null;
    this._renderer = null;
    this._camera = null;
    this._loadingOverlay = null;
    this._mainScene = null;
    this._scene3DDebugMenu = null;
    this._orbitControls = null;
    this._audioListener = null;
    this._mixer=null;// 动画轨道器
    this._windowSizes = {};
    this._isAssetsLoaded = false;
    this.delta=0;


    SCENE_CONFIG.isMobile = isMobile(window.navigator).any;
    this._isKeyboardShortcutsShown = false;
    // const stats=this.stats = new Stats();
    // document.getElementsByTagName("body")[0].appendChild(stats.dom); // hyl 临时添加帧率测试
    this._init();//初始化场景
  }

  createGameScene() {


    const data = {
      scene: this._scene,
      camera: this._camera,
      renderer: this._renderer,
      orbitControls: this._orbitControls,
      audioListener: this._audioListener,
      mixer:this._mixer
    };

    this._mainScene = new MainScene(data);


  }

  afterAssetsLoaded() {
    this._isAssetsLoaded = true;//资源加载完成 开启渲染

    this._loadingOverlay.hide();// 关闭 loading 页面

    this._mainScene.afterAssetsLoad();// 游戏资源装载到场景中
    this._setupBackgroundColor();
  }

 

  _init() {
    this._initBlack();
    this._initThreeJS();
    this._initUpdate();
  }

  _initBlack() {// 初始化loader 方法
    // console.log(Loader.assets,'before',CanvasDriver)
    const engine = new Engine('container', Loader, CanvasDriver, [Input, MasterAudio]);
    // // console.log(engine,'---engine',Input, MasterAudio)
    // engine.pauseOnBlur = false;

    // engine.pauseOnHide = false;
    engine.start();

    engine.stage.setSize(640, 960);
    // const load=new Loader();
    // console.log(load.assets,'----',Loader.assets)
    console.log(Loader.assets)
    // engine.stage.scaleMode = StageScaleMode.LETTERBOX;
  }

  _initThreeJS() {
    this._initScene();// 初始化场景
    this._initRenderer();//初始化渲染器
    this._initCamera();//初始化相机
    this._initLights();//初始化灯光
    this._initFog();//初始化雾
    // this._initAxesHelper();
    this._initLoadingOverlay();//初始化 load 遮罩层
    this._initOnResize();//窗口监测事件
    this._initAudioListener();//初始化声音通道

    this._initScene3DDebugMenu();//初始化辅助
  }

  _initScene() {
    this._scene = new THREE.Scene();
    this._mixer = new THREE.AnimationMixer(this._scene);
  }

  _initRenderer() {
    this._windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    const canvas = document.querySelector('canvas.webgl');

    const renderer = this._renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: SCENE_CONFIG.antialias,
      alpha:true
    });

    renderer.setSize(this._windowSizes.width, this._windowSizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, SCENE_CONFIG.maxPixelRatio));

    // renderer.outputColorSpace = THREE.SRGBColorSpace;

    // renderer.useLegacyLights = false;
    // renderer.physicallyCorrectLights = true;
    // renderer.outputEncoding = THREE.sRGBEncoding;

    // renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // renderer.toneMappingExposure = 1;

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  }

  _initCamera() {
    const camera = this._camera = new THREE.PerspectiveCamera(SCENE_CONFIG.fov.desktop, this._windowSizes.width / this._windowSizes.height, 0.5, 40);
    this._scene.add(camera);

    if (SCENE_CONFIG.isMobile) {
      this._camera.fov = this._windowSizes.width < this._windowSizes.height ? SCENE_CONFIG.fov.mobile.portrait : SCENE_CONFIG.fov.mobile.landscape;
      this._camera.updateProjectionMatrix();
    }
  }

  _initLights() {

      const ambientLight = new THREE.AmbientLight(0xffffff, 1);
      ambientLight.color.setHex(0xFFFFFF).convertLinearToSRGB();
      this._scene.add(ambientLight);


    const directionalLight = new THREE.DirectionalLight(0xffffff,5);
    directionalLight.color.setHex(0xFFFFFF).convertLinearToSRGB();
    directionalLight.position.set(-19.196,19.534,7.500);
    this._scene.add(directionalLight);

    directionalLight.castShadow = true;

    const mapsize=2048;
    directionalLight.shadow.mapSize=new THREE.Vector2(mapsize,mapsize)
    directionalLight.shadow.camera.near = 1;
    directionalLight.shadow.camera.far = 550;

    directionalLight.shadow.camera.left = -20;
    directionalLight.shadow.camera.right = 20;
    directionalLight.shadow.camera.top = 20;
    directionalLight.shadow.camera.bottom = -20;

    // directionalLight.shadow.bias = 0.0001;

    // const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
    // this._scene.add(directionalLightCameraHelper);
  }

  _initFog() {
    if (SCENE_CONFIG.fog.enabled) {
      let near = SCENE_CONFIG.fog.desktop.near;
      let far = SCENE_CONFIG.fog.desktop.far;

      if (SCENE_CONFIG.isMobile) {
        near = this._windowSizes.width < this._windowSizes.height ? SCENE_CONFIG.fog.mobile.portrait.near : SCENE_CONFIG.fog.mobile.landscape.near;
        far = this._windowSizes.width < this._windowSizes.height ? SCENE_CONFIG.fog.mobile.portrait.far : SCENE_CONFIG.fog.mobile.landscape.far;
      }

      this._scene.fog = new THREE.Fog(SCENE_CONFIG.backgroundColor, near, far);
    }
  }



  _initLoadingOverlay() {// loading 遮罩 模型
    const loadingOverlay = this._loadingOverlay = new LoadingOverlay();
 
    this._scene.add(loadingOverlay);
  }

  _initOnResize() {// 窗口监测变化
    window.addEventListener('resize', () => this._onResize());
  }

  _onResize() {
    this._windowSizes.width = window.innerWidth;
    this._windowSizes.height = window.innerHeight;
    const pixelRatio = Math.min(window.devicePixelRatio, SCENE_CONFIG.maxPixelRatio);

    this._camera.aspect = this._windowSizes.width / this._windowSizes.height;
    this._camera.updateProjectionMatrix();

    this._renderer.setSize(this._windowSizes.width, this._windowSizes.height);
    this._renderer.setPixelRatio(pixelRatio);

    if (SCENE_CONFIG.isMobile) {
      this._camera.fov = this._windowSizes.width < this._windowSizes.height ? SCENE_CONFIG.fov.mobile.portrait : SCENE_CONFIG.fov.mobile.landscape;
      this._camera.updateProjectionMatrix();

      if (SCENE_CONFIG.fog.enabled) {
        this._scene.fog.near = this._windowSizes.width < this._windowSizes.height ? SCENE_CONFIG.fog.mobile.portrait.near : SCENE_CONFIG.fog.mobile.landscape.near;
        this._scene.fog.far = this._windowSizes.width < this._windowSizes.height ? SCENE_CONFIG.fog.mobile.portrait.far : SCENE_CONFIG.fog.mobile.landscape.far;
      }
    }
  }

  _setupBackgroundColor() {
    this._scene.background = new THREE.Color(0xffffff);
    const texture=Loader.assets['bg'];
    this._scene.background=texture
    // const texture = Loader.assets['environment'];
    // const renderTarget = new THREE.WebGLCubeRenderTarget(texture.image.height);
    // renderTarget.fromEquirectangularTexture(this._renderer, texture);
    // this._scene.background = renderTarget.texture;
  }

  _initAudioListener() {
    const audioListener = this._audioListener = new THREE.AudioListener();
    this._camera.add(audioListener);
  }

  _initScene3DDebugMenu() {
    this._scene3DDebugMenu = new Scene3DDebugMenu(this._scene, this._camera, this._renderer);
    this._orbitControls = this._scene3DDebugMenu.getOrbitControls();
  }

  _initUpdate() {
    const clock = new THREE.Clock(true);
    const clock2 = new THREE.Clock(true);
    const clock3 = new THREE.Clock(true);
    let delta=0; 
    let interval = 1 /34;
    let delta2=0; 
    let interval2 = 1 /64;
    const update = () => {
      delta+= Math.min(clock2.getDelta(), 0.1);
      delta2+= Math.min(clock3.getDelta(), 0.1);
      window.requestAnimationFrame(update);
   

      const deltaTime = clock.getDelta();
      

      // if (delta > interval) {
       
      // this.stats.update()
        if (this._isAssetsLoaded) {
          
        

          if (this._mainScene) {
            if(delta2>interval2){//计算帧率64帧
              this._mainScene.update(deltaTime);
              delta2 = delta2 % interval2;
              if(this._mixer){
                this._mixer.update(deltaTime)
                
              }
            }
            
          }
          if (delta > interval) {//渲染帧率30帧
            this._renderer.render(this._scene, this._camera);
            // this._scene3DDebugMenu.update();// 更新相机控制器（在开发时相机可以操作选择） 发布时关闭此方法
            
            TWEEN.update();
            delta = delta % interval;
            
            
          }
          
        }
        
        
        // delta = delta % interval;
        
      // }
   
      
    }

    update();
  }
}
