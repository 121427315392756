import { Black, DisplayObject, Ease, TextField, Tween ,Sprite} from "black-engine";
import ScreenAbstract from "../screen-abstract";
import { GLOBAL_VARIABLES } from "../../../scene/game-scene/game-field/data/global-variables";
import { CONSUMABLES_CONFIG, CONSUMABLE_TYPE } from "../../../scene/game-scene/game-field/consumables/data/consumables-config";
import { SCORE_CONFIG } from "../../../scene/game-scene/game-field/data/score-config";
import ProgressBar from "../../progress-bar";
import { ROUND_CONFIG } from "../../../scene/game-scene/game-field/data/game-config";
import SCENE_CONFIG from "../../../core/configs/scene-config";
import DPad from "./d-pad";
// import { Sprite } from "three";

export default class GameplayScreen extends ScreenAbstract {
  constructor() {
    super();

    this._scoreNumberText = null;
    this._goText = null;
    this._roundNumberText = null;
    this._collectedScore = null;
    this._boosterText = null;
    this._boosterGroup = null;
    this._powerUpProgressBar = null;
    this._roundProgressBar = null;
    this._roundGroup = null;
    this._newRoundText = null;
    this._dPad = null;
    this.fontStyle='方正流行体简体';
  }

  update(dt) {
    this._roundProgressBar.update(dt);
  }

  show() {
    super.show();

    const duration = ROUND_CONFIG.roundDuration;
    this._roundProgressBar.show(0xffff00, 120, duration);

    this._showNewRound();
  }

  updateLives() {
    const lives = GLOBAL_VARIABLES.playerLives;
    this._livesNumberText.text = `${lives}`;
  }

  updateRound() {
    const currentRound = GLOBAL_VARIABLES.round;
    this._roundNumberText.text = `${currentRound + 1}`;

    if (currentRound !== ROUND_CONFIG.maxRound) {
      const duration = ROUND_CONFIG.roundDuration;
      this._roundProgressBar.show(0xffff00, 120, duration);
    }

    this._showNewRound();
  }

  setScore(value) {
    this._scoreNumberText.text = `${value}`;
  }

  onConsumableCollect(consumableType, position) {
    this._collectedScore.removeComponent(this._collectedScore.getComponent(Tween));

    let text;

    if (consumableType === CONSUMABLE_TYPE.SmallCandy || consumableType === CONSUMABLE_TYPE.BigCandy) {
      this._collectedScore.textColor = 0xffffff;
      const round = GLOBAL_VARIABLES.round;
      const score = SCORE_CONFIG.consumables[consumableType][round];
      text = `+${score}`;
      this._collectedScore.text = text;
      this._collectedScore.visible = true;
      this._collectedScore.alpha = 1;
      this._collectedScore.x = position.x;
      this._collectedScore.y = position.y;

      const tween = new Tween({ y: position.y - 30, alpha: 0 }, 0.8, { ease: Ease.sinusoidalOut, });
      this._collectedScore.add(tween);

      tween.on('complete', () => {
        this._collectedScore.visible = false;
      });
    }
    // if (consumableType === CONSUMABLE_TYPE.BoosterCandyEnemiesSlow || consumableType === CONSUMABLE_TYPE.BoosterCandyPlayerInvulnerability || consumableType === CONSUMABLE_TYPE.BoosterCandyPlayerSpeed) {
    //   const config = CONSUMABLES_CONFIG[consumableType];
    //   // this._collectedScore.textColor = config.color;
    //   // text = `${CONSUMABLES_CONFIG[consumableType].name}`;

      

     
    // }
    this.PlayerSpeed.visible=false;
    this.EnemiesSlow.visible=false;
    this.Invulnerability.visible=false;
    switch(consumableType){
      case CONSUMABLE_TYPE.BoosterCandyEnemiesSlow:
        this._showBoosterProgressBar(consumableType);

        this.EnemiesSlow.visible=true;

        break;
      case CONSUMABLE_TYPE.BoosterCandyPlayerInvulnerability:

        this.Invulnerability.visible=true;
        this._showBoosterProgressBar(consumableType);

        break;
      case CONSUMABLE_TYPE.BoosterCandyPlayerSpeed:
    
        this.PlayerSpeed.visible=true;
        this._showBoosterProgressBar(consumableType);
   
        break;

    }

   

    

    
  }

  startInvulnerabilityBooster(duration) {
    const type = CONSUMABLE_TYPE.BoosterCandyPlayerInvulnerability;
    const config = CONSUMABLES_CONFIG[type];

    this._boosterGroup.visible = true;

    // this._boosterText.text = config.name;
    // this._boosterText.textColor = config.color;

    this._powerUpProgressBar.show(config.color, config.progressBarWidth, duration);
  }

  showGoText() {
    // this._goText.visible = true;
    // this._goText.alpha = 1;
    // this._goText.scale = 0;

    // const showTween = new Tween({ scale: 1 }, 0.4, { ease: Ease.backOut, delay: 0.2 });
    // this._goText.add(showTween);

    // showTween.on('complete', () => {
    //   const hideTween = new Tween({ alpha: 0 }, 0.3, { ease: Ease.sinusoidalIn, delay: 0.4 });
    //   this._goText.add(hideTween);

    //   hideTween.on('complete', () => {
    //     this._goText.visible = false;
    //   });
    // });
  }

  reset() {
    this._boosterGroup.visible = false;
    this._powerUpProgressBar.reset();
    this._roundProgressBar.reset();
  }

  hideTutorial() {
    const hideTween = new Tween({ scale: 0 }, 0.5, { ease: Ease.backIn, delay: 0.4 });
    this._tutorial.add(hideTween);
  }

  stopBooster() {
    this._boosterGroup.visible = false;
    this._powerUpProgressBar.reset();
  }

  onOverlayMove() {
    if (this.visible && Black.engine.containerElement.style.cursor !== 'grab' && Black.engine.containerElement.style.cursor !== 'grabbing') {
      Black.engine.containerElement.style.cursor = 'grab';
    }
  }

  _showNewRound() {
    const text=['初入战场','逐渐成长','崭露头角','勇往直前','顽强抵抗','掌握技巧','策略制胜','决战巅峰','无尽挑战','生存至最后一刻']
    this._newRoundText.text = text[GLOBAL_VARIABLES.round];//`Round: ${GLOBAL_VARIABLES.round + 1}`;

    this._newRoundText.visible = true;
    this._newRoundText.alpha = 0.85;
    this._newRoundText.scale = 0;

    const showTween = new Tween({ scale:0.8 }, 0.4, { ease: Ease.backOut, delay: 0.8 });
    this._newRoundText.add(showTween);

    showTween.on('complete', () => {
      const hideTween = new Tween({ alpha: 0 }, 0.3, { ease: Ease.sinusoidalIn, delay: 0.4 });
      this._newRoundText.add(hideTween);

      hideTween.on('complete', () => {
        this._newRoundText.visible = false;
      });
    });
  }

  _showBoosterProgressBar(type) {
    this._boosterGroup.visible = false;
    this._powerUpProgressBar.reset();

    const config = CONSUMABLES_CONFIG[type];

    this._boosterGroup.visible = true;

    // this._boosterText.text = config.name;
    // this._boosterText.textColor = config.color;



    this._powerUpProgressBar.show(config.color, config.progressBarWidth, config.duration);
  }

  _init() {
    this._initScore();
    this._initRoundText();
    this._initGoText();
    this._initCollectedScore();
    this._initBoosterGroup();
    this._initRoundProgressBar();
    this._initNewRoundText();
    this._initDPad();
    this._initTutorial();
    this._initLives();
    this._initZombieTips();
  }

  _initScore() {
    const scoreGroup = this._scoreGroup = new DisplayObject();
    this.add(scoreGroup);

    const scoreCaption =new Sprite('sunBg');;//= new TextField('得分:', this.fontStyle, 0x000000, 50);

    scoreCaption.alignAnchor(0, 0.5);
    scoreGroup.add(scoreCaption);
  
  
    const scoreNumberText = this._scoreNumberText = new TextField('0', this.fontStyle, 0xffffff, 20);

    scoreNumberText.alignAnchor(0, 0.5);
    scoreGroup.add(scoreNumberText);
    scoreNumberText.x = 50;
  
    // scoreGroup.x=200;
  }

  _initRoundText() {
    const roundGroup = this._roundGroup = new DisplayObject();
    this.add(roundGroup);

    const roundCaption = this._roundCaption = new Sprite('leveBg');//new TextField('回合:', this.fontStyle, 0x000000, 60);
    roundGroup.add(roundCaption);

    roundCaption.alignAnchor(0, 0.5);

    const roundNumberText = this._roundNumberText = new TextField('1', this.fontStyle, 0xffffff, 20);
    roundGroup.add(roundNumberText);

    roundNumberText.alignAnchor(0, 0.5);

    roundNumberText.x = 60;
    // roundNumberText.y=40
  }

  _initGoText() {
    const goText = this._goText = new TextField('来吧!', this.fontStyle, 0xffffff, 130);
    this.add(goText);
// 
    goText.dropShadow = true;
    goText.shadowBlur = 1;
    goText.shadowAlpha = 0.4;
    goText.shadowDistanceX = 4;
    goText.shadowDistanceY = 4;

    goText.alignAnchor(0.5, 0.5);
    goText.visible = false;
  }

  _initCollectedScore() {
    const collectedScore = this._collectedScore = new TextField('', this.fontStyle, 0x000000, 50);
    this.add(collectedScore);

    collectedScore.dropShadow = true;
    collectedScore.shadowBlur = 1;
    collectedScore.shadowAlpha = 0.4;
    collectedScore.shadowDistanceX = 3;
    collectedScore.shadowDistanceY = 3;

    collectedScore.alignAnchor(0.5, 0.5);
    collectedScore.visible = false;
  }

  _initZombieTips(){
    const Invulnerability=this.Invulnerability=new Sprite('Invulnerability');
    Invulnerability.alignAnchor(0.5, 0.5);
    Invulnerability.scale=0.6;
    Invulnerability.visible=false;
    // Invulnerability.x=position.x,
    // Invulnerability.y=position.x;
    this._boosterGroup.add(Invulnerability);
    
    const PlayerSpeed=this.PlayerSpeed=new Sprite('PlayerSpeed');
    PlayerSpeed.alignAnchor(0.5, 0.5);
    PlayerSpeed.scale=0.6;
    PlayerSpeed.visible=false;
    this._boosterGroup.add(PlayerSpeed);

    const EnemiesSlow=this.EnemiesSlow=new Sprite('EnemiesSlow');
    EnemiesSlow.alignAnchor(0.5, 0.5);
    EnemiesSlow.scale=0.6;
    EnemiesSlow.visible=false;
    this._boosterGroup.add(EnemiesSlow);

  }

  _initBoosterGroup() {
    const boosterGroup = this._boosterGroup = new DisplayObject();
    this.add(boosterGroup);

    boosterGroup.visible = false;

    this._initBoosterProgressBar();
    this._initBoosterText();
  }

  _initBoosterText() {
    // const boosterText = this._boosterText = new TextField('', this.fontStyle, 0xffffff, 50);
    // this._boosterGroup.add(boosterText);

    // boosterText.alignAnchor(0.5, 0.5);

    // const config = CONSUMABLES_CONFIG[CONSUMABLE_TYPE.BoosterCandyEnemiesSlow];
    // const color = config.color;
    // boosterText.textColor = color;

    // boosterText.dropShadow = true;
    // boosterText.shadowBlur = 1;
    // boosterText.shadowAlpha = 0.4;
    // boosterText.shadowDistanceX = 2;
    // boosterText.shadowDistanceY = 2;
  }

  _initBoosterProgressBar() {
    const powerUpProgressBar = this._powerUpProgressBar = new ProgressBar(true);
    this._boosterGroup.add(powerUpProgressBar);

    powerUpProgressBar.on('complete', () => {
      this._boosterGroup.visible = false;
    });
  }

  _initRoundProgressBar() {
    const roundProgressBar = this._roundProgressBar = new ProgressBar(false);
    this._roundGroup.add(roundProgressBar);

    roundProgressBar.x = 0;
    roundProgressBar.y = 15;
  }

  _initNewRoundText() {
    const newRoundText = this._newRoundText = new TextField('回合', this.fontStyle, 0xffffff, 110);
    this.add(newRoundText);

    newRoundText.alignAnchor(0.5, 0.5);

    newRoundText.dropShadow = true;
    newRoundText.shadowBlur = 1;
    newRoundText.shadowAlpha = 0.4;
    newRoundText.shadowDistanceX = 4;
    newRoundText.shadowDistanceY = 4;

    newRoundText.visible = false;
  }

  _initDPad() {
    if (!SCENE_CONFIG.isMobile) {
      return;
    }

    const dPad = this._dPad = new DPad();
    this.add(dPad);

    dPad.on('onLeft', () => this.post('onLeft'));
    dPad.on('onRight', () => this.post('onRight'));
    dPad.on('onUp', () => this.post('onUp'));
    dPad.on('onDown', () => this.post('onDown'));
    dPad.on('onJump', () => this.post('onJump'));
  }

  _initTutorial() {
    const tutorial = this._tutorial = new TextField('Use WASD or arrows to move', 'halloween_spooky', 0xffffff, 60);
    this.add(tutorial);

    tutorial.alignAnchor(0.5, 0.5);

    tutorial.dropShadow = true;
    tutorial.shadowBlur = 1;
    tutorial.shadowAlpha = 0.4;
    tutorial.shadowDistanceX = 4;
    tutorial.shadowDistanceY = 4;

    // if (SCENE_CONFIG.isMobile) {
      tutorial.visible = false;
    // }
  }

  _initLives() {
    const livesGroup = this._livesGroup = new DisplayObject();
    this.add(livesGroup);

    // const livesCaption = new TextField('生命值:', this.fontStyle, 0x000000, 40);
    const livesCaption=new Sprite('peaBg');//

    livesCaption.alignAnchor(0, 0.5);
    livesGroup.add(livesCaption);
    // livesCaption.x = -105;

    const livesNumberText = this._livesNumberText = new TextField('0', this.fontStyle, 0xffffff, 20);

    livesNumberText.alignAnchor(0, 0.5);
    livesGroup.add(livesNumberText);
    livesNumberText.x = 50;
  }

  _onResize() {
    const bounds = Black.stage.bounds;

    // this._goText.x = bounds.left + bounds.width * 0.5;
    // this._goText.y = bounds.top + bounds.height * 0.5;

    this._scoreGroup.x =  bounds.right - 300;
    this._scoreGroup.y = bounds.top + 70;

    this._roundGroup.x = bounds.left + bounds.width * 0.5;
    this._roundGroup.y = bounds.top + 70;

    this._boosterGroup.x = bounds.left + bounds.width * 0.5;
    this._boosterGroup.y = bounds.top + 160;

    this._newRoundText.x = bounds.left + bounds.width * 0.5;
    this._newRoundText.y = bounds.top + bounds.height * 0.5;

    this._tutorial.x = bounds.left + bounds.width * 0.5;
    this._tutorial.y = bounds.top + bounds.height * 0.8;

    this._livesGroup.x = bounds.left + 300;
    this._livesGroup.y = bounds.top + 70;

    if (SCENE_CONFIG.isMobile) {
      if (window.innerWidth < window.innerHeight) {
        const {x,y,width,height}=bounds;
  
        this._scoreGroup.x =width/3+5 ;//bounds.left + bounds.width * 0.7 - 10;
        this._scoreGroup.y = bounds.top + 70;

        this._livesGroup.x = 10;
        this._livesGroup.y =  70;
  
        this._roundGroup.x = width/3*2;
        this._roundGroup.y = bounds.top + 70;
  
        this._boosterGroup.x = bounds.left + bounds.width * 0.5;
        this._boosterGroup.y = bounds.top + 235;

        this._dPad.scale = 1;
        this._dPad.x = bounds.right - 200;
        this._dPad.y = bounds.bottom - 240;
      } else {
        this._dPad.scale = 1.5;
        this._dPad.x = bounds.right - 240;
        this._dPad.y = bounds.bottom - 300;
      }
    }
  }
}
