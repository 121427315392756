import * as THREE from 'three';
import TWEEN from 'three/addons/libs/tween.module.js';
import { ENEMY_TYPE } from './data/enemy-data';
import ENEMY_CLASS from './data/enemy-class';
import { MessageDispatcher } from 'black-engine';
import { GAME_STATE } from '../data/game-data';
import { GHOSTS_COLOR_BY_TYPE, GHOST_CONFIG } from './data/ghost-config';
import Delayed from '../../../../core/helpers/delayed-call';
import { randomBetween, randomFromArray } from '../../../../core/helpers/helpers';
import { GLOBAL_VARIABLES } from '../data/global-variables';
import { EVIL_PUMPKIN_CONFIG } from './data/evil-pumpkin-config';
import { ROUND_CONFIG } from '../data/game-config';
import { CONSUMABLES_CONFIG, CONSUMABLE_TYPE } from '../consumables/data/consumables-config';

export default class EnemiesController extends THREE.Group {
  constructor(mixer) {
    super();

    this.events = new MessageDispatcher();

    this._enemiesPool = {};
    this._activeEnemies = {};
    this._ghostSpawnTimer = null;
    this._enemiesSlowBoosterTimer = null;
    this._mixer=mixer;
    this._initEnemiesObjects();
  }

  update(dt) {
    if (GLOBAL_VARIABLES.gameState !== GAME_STATE.Gameplay) {
      return;
    }

    this._iterateActiveEnemies((enemy) => {
      enemy.update(dt);
    });
  }

  activateSpawnEnemies() {// 初始化敌人
    this._ghostSpawn();
    this._spawnFirstEvilPumpkin();
    this._spawnSkeleton();
  }

  stopTweens() {
    this._iterateActiveEnemies((enemy) => enemy.stopTweens());
  }

  reset() {
    this._removeAllEnemies();
    this._ghostSpawnTimer?.stop();
    this._enemiesSlowBoosterTimer?.stop();
    this._updateSpeedMultiplier();
  }

  debugChangedHelper() {// 后期删掉
    this._iterateActiveEnemies((enemy) => {
      enemy.debugChangedHelper();
    });
  }

  onRoundChanged() {// 敌人数量安全线设置
    this._updateGhostOnRoundChanged();
    this._updateEvilPumpkinOnRoundChanged();
  }

  getActiveEnemiesPositions() {
    const positions = [];

    this._iterateActiveEnemies((enemy) => {
      if (enemy.isBodyActive()) {
        positions.push(enemy.getPosition());
      }
    });

    return positions;
  }

  stopEnemiesSlowBooster() {
    this._enemiesSlowBoosterTimer?.stop();
    this._updateSpeedMultiplier();
  }  

  startEnemiesSlowBooster() {
    const boosterConfig = CONSUMABLES_CONFIG[CONSUMABLE_TYPE.BoosterCandyEnemiesSlow];
    GHOST_CONFIG.speedMultiplier = boosterConfig.speedMultiplier;
    EVIL_PUMPKIN_CONFIG.speedMultiplier = boosterConfig.speedMultiplier;

    this._iterateActiveEnemies((enemy) => {
      if (enemy.getType() === ENEMY_TYPE.EvilPumpkin) {
        enemy.updateJumpTime();
      }
    });

    this._iteratePoolEnemies((enemy) => {
      if (enemy.getType() === ENEMY_TYPE.EvilPumpkin) {
        enemy.updateJumpTime();
      }
    });

    this._enemiesSlowBoosterTimer = new TWEEN.Tween({ value: 0 })
      .to({ value: 1 }, boosterConfig.duration)
      .start()
      .onComplete(() => {
        this._updateSpeedMultiplier();
        GLOBAL_VARIABLES.activeBooster = null;
      });
  }


  _updateSpeedMultiplier() {
    const round = GLOBAL_VARIABLES.round;
    const ghostRoundConfig = ROUND_CONFIG.enemies[ENEMY_TYPE.Ghost][round];
    const evilPumpkinRoundConfig = ROUND_CONFIG.enemies[ENEMY_TYPE.EvilPumpkin][round];

    GHOST_CONFIG.speedMultiplier = ghostRoundConfig.speedMultiplier;
    EVIL_PUMPKIN_CONFIG.speedMultiplier = evilPumpkinRoundConfig.speedMultiplier;

    this._iterateActiveEnemies((enemy) => {
      if (enemy.getType() === ENEMY_TYPE.EvilPumpkin) {
        enemy.updateJumpTime();
      }
    });

    this._iteratePoolEnemies((enemy) => {
      if (enemy.getType() === ENEMY_TYPE.EvilPumpkin) {
        enemy.updateJumpTime();
      }
    });
  }

  _updateGhostOnRoundChanged() {// 超出等级数量 需要及时干掉一个
    const round = GLOBAL_VARIABLES.round;
    const ghostRoundConfig = ROUND_CONFIG.enemies[ENEMY_TYPE.Ghost][round];

    if (!GLOBAL_VARIABLES.activeBooster) {
      GHOST_CONFIG.speedMultiplier = ghostRoundConfig.speedMultiplier;
    }
    
    const currentGhostCount = this._activeEnemies[ENEMY_TYPE.Ghost].length;
    const spawnCount = ghostRoundConfig.maxCount - currentGhostCount;

    if (spawnCount < 0) {
      const removeCount = Math.abs(spawnCount);

      for (let i = 0; i < removeCount; i++) {
        const enemy = this._activeEnemies[ENEMY_TYPE.Ghost][i];
        enemy.kill();
      }
    }
  }

  _updateEvilPumpkinOnRoundChanged() {//调用频率 10秒左右
    if (!GLOBAL_VARIABLES.activeBooster) {
      this._updateEvilPumpkinSpeedMultiplier();
    }
    console.log('1.4----1.4',GLOBAL_VARIABLES.gameState)
    if (GLOBAL_VARIABLES.gameState === GAME_STATE.Gameplay) {// 生成
      this._evilPumpkinSpawn();
    }
  }

  _updateEvilPumpkinSpeedMultiplier() {
    const round = GLOBAL_VARIABLES.round;
    const evilPumpkinRoundConfig = ROUND_CONFIG.enemies[ENEMY_TYPE.EvilPumpkin][round];

    EVIL_PUMPKIN_CONFIG.speedMultiplier = evilPumpkinRoundConfig.speedMultiplier;

    this._iterateActiveEnemies((enemy) => {
      if (enemy.getType() === ENEMY_TYPE.EvilPumpkin) {
        enemy.updateJumpTime();
      }
    });

    this._iteratePoolEnemies((enemy) => {
      if (enemy.getType() === ENEMY_TYPE.EvilPumpkin) {
        enemy.updateJumpTime();
      }
    });
  }

  _spawnFirstEvilPumpkin() {//生成僵尸类型1 

    this._spawnEnemy(ENEMY_TYPE.EvilPumpkin);
    Delayed.call(2000, () => {
      this._spawnEnemy(ENEMY_TYPE.EvilPumpkin);
      // this._evilPumpkinSpawn();// 
    });
  }

  _spawnSkeleton() {//生成僵尸类型2

    this._spawnEnemy(ENEMY_TYPE.Skeleton);
    Delayed.call(2000, () => {
      this._spawnEnemy(ENEMY_TYPE.Skeleton); 
    });
  
   
  }

  _ghostSpawn() {//生成僵尸类型3// 递归 不断生成 僵尸
    const spawnTime = randomBetween(GHOST_CONFIG.spawnTime.min, GHOST_CONFIG.spawnTime.max);

    this._ghostSpawnTimer = new TWEEN.Tween({ value: 0 })
      .to({ value: 1 }, spawnTime)
      .start()
      .onComplete(() => {
        if (GLOBAL_VARIABLES.gameState !== GAME_STATE.Gameplay) {
          return;
        }
  
        const round = GLOBAL_VARIABLES.round;
        const ghostRoundConfig = ROUND_CONFIG.enemies[ENEMY_TYPE.Ghost][round];
        const maxCount = ghostRoundConfig.maxCount;
  
        if (this._activeEnemies[ENEMY_TYPE.Ghost].length < 4) {// 控制 当前等级场景数据量
          this._spawnEnemy(ENEMY_TYPE.Ghost);
        }
      
        this._ghostSpawn();
      });
  }

  _evilPumpkinSpawn() {
    const round = GLOBAL_VARIABLES.round;
    const evilPumpkinRoundConfig = ROUND_CONFIG.enemies[ENEMY_TYPE.EvilPumpkin][round];
    const currentCount =  ROUND_CONFIG.enemies[ENEMY_TYPE.Skeleton][round].count;
    
    // const spawnCount = evilPumpkinRoundConfig.count - currentCount;

    // this._spawnEnemy(ENEMY_TYPE.Skeleton);


  

    const count=evilPumpkinRoundConfig.count
    for (let i = 0; i < count; i++) {
      this._spawnEnemy(ENEMY_TYPE.EvilPumpkin);
      // this._spawnEnemy(ENEMY_TYPE.Skeleton);
    }
    for (let i = 0; i < currentCount; i++) {
      this._spawnEnemy(ENEMY_TYPE.Skeleton);
   
    }
  }

  _removeAllEnemies() {// 移除场景中 控制类角色模型（需要回收内存）
    this._removeEnemiesByType(ENEMY_TYPE.Ghost);
    this._removeEnemiesByType(ENEMY_TYPE.EvilPumpkin);
    this._removeEnemiesByType(ENEMY_TYPE.Skeleton);
    this._resetPoolEnemies();
  }

  _removeEnemiesByType(type) {
    const enemies = this._activeEnemies[type];
    
    for (let i = 0; i < enemies.length; i++) {
      const enemy = enemies[i];
      enemy.hide();
      enemy.reset();
      this._enemiesPool[type].push(enemy);
    }

    this._activeEnemies[type] = [];
  }

  _resetPoolEnemies() {
    this._iteratePoolEnemies((enemy) => {
      enemy.reset();
    });
  }

  _removeEnemy(enemy) {
    const type = enemy.getType();
    const index = this._findActiveEnemyIndex(enemy);
    this._activeEnemies[type].splice(index, 1);
    this._enemiesPool[type].push(enemy);
  }

  _spawnEnemy(type) {
    const enemy = this._getEnemyFromPool(type);
    enemy.setSpawnPosition();
    enemy.spawn();

    this._activeEnemies[type].push(enemy);

    return enemy;
  }

  _getEnemyFromPool(type) {
    if (this._enemiesPool[type].length === 0) {
      const enemy = this._createEnemy(type);
      return enemy;
    }

    const enemy = this._enemiesPool[type].pop();

    return enemy;
  }

  _createEnemy(type) {//创建初始化所有类型坏人
    const className = ENEMY_CLASS[type];// enemy_class

    
    const enemy = new className(this._mixer);
    
    this.add(enemy);
    this._initEnemySignals(enemy);

    return enemy;
  }

  _initEnemySignals(enemy) {//初始化坏人事件
    enemy.events.on('positionChanged', () => this.events.post('positionChanged'));
    enemy.events.on('onKilled', (msg, enemyToKill) => this._removeEnemy(enemyToKill));
  }

  _initEnemiesObjects() {//初始化 坏人 类型

    for (const key in ENEMY_TYPE) {
      const enemyType = ENEMY_TYPE[key];
      this._enemiesPool[enemyType] = [];
      this._activeEnemies[enemyType] = [];
    }
  }

  _iterateActiveEnemies(callback) {
    for (const key in this._activeEnemies) {
      const enemies = this._activeEnemies[key];
      for (let i = 0; i < enemies.length; i++) {
        const enemy = enemies[i];
        callback(enemy);
      }
    }
  }

  _iteratePoolEnemies(callback) {
    for (const key in this._enemiesPool) {
      const enemies = this._enemiesPool[key];
      for (let i = 0; i < enemies.length; i++) {
        const enemy = enemies[i];
        callback(enemy);
      }
    }
  }

  _findActiveEnemyIndex(enemy) {
    const enemyType = enemy.getType();

    for (let i = 0; i < this._activeEnemies[enemyType].length; i++) {
      const activeEnemy = this._activeEnemies[enemyType][i];
      if (activeEnemy === enemy) {
        return i;
      }
    }
  }
}
