import { GHOSTS_COLOR_TYPE } from "../../environment/environment-objects/data/environment-objects-config";
import { GAME_STATE, MAP_TYPE } from "./game-data";

const GLOBAL_VARIABLES = {
  currentLevel: null,
  gameState: GAME_STATE.Idle,
  round: 0,
  playerPosition: { row: 0, column: 0 },
  playerLives: 3,
  activeBooster: null,
  boosterSpawned: false,
  ghostsColorType: GHOSTS_COLOR_TYPE.White,
  maps: {
    [MAP_TYPE.Consumable]: [],// 奖励消耗品 位置矩阵
    [MAP_TYPE.Obstacle]: [],//障碍 墓碑
    [MAP_TYPE.Ghost]: [],// 鬼 
    [MAP_TYPE.EvilPumpkin]: [],//南瓜
    [MAP_TYPE.Skeleton]: [],//新增僵尸类型
    [MAP_TYPE.Bullet]:[]// 子弹
  }
}

export { GLOBAL_VARIABLES };
