import { ENEMY_TYPE } from "../enemies/data/enemy-data";

const GAME_CONFIG = {
  cellSize: 1,//模型尺寸
  gravity: 9.8,//重力
  sceneBlur: 5,//模糊程度
  helpers: false,
}

const ROUND_CONFIG = {
  maxRound: 10,
  roundDuration: 30000,
  player: [
    { speedMultiplier: 1 },
    { speedMultiplier: 1.1 },
    { speedMultiplier: 1.2 },
    { speedMultiplier: 1.3 },
    { speedMultiplier: 1.4 },
    { speedMultiplier: 1.4 },
    { speedMultiplier: 1.4 },
    { speedMultiplier: 1.4 },
    { speedMultiplier: 1.4},
    { speedMultiplier: 1.4 },
  ],
  enemies: {
    [ENEMY_TYPE.Ghost]: [
      { speedMultiplier: 0.5, maxCount: 4 },
      { speedMultiplier: 0.55, maxCount: 5 },
      { speedMultiplier: 0.6, maxCount: 7 },
      { speedMultiplier: 0.65, maxCount: 8 },
      { speedMultiplier: 0.7, maxCount: 10 },
      { speedMultiplier: 0.75, maxCount: 10 },
      { speedMultiplier: 0.8, maxCount: 10 },
      { speedMultiplier: 0.85, maxCount: 10 },
      { speedMultiplier: 0.9, maxCount: 10 },
      { speedMultiplier: 1, maxCount: 10 },
    ],
    [ENEMY_TYPE.EvilPumpkin]: [
      { speedMultiplier: 1, count: 1 },
      { speedMultiplier: 1.1, count:2 },
      { speedMultiplier: 1.2, count: 3 },
      { speedMultiplier: 1.3, count:4},
      { speedMultiplier: 1.4, count:5},
      { speedMultiplier: 1.6, count: 6},
      { speedMultiplier: 1.7, count: 7},
      { speedMultiplier: 1.8, count: 8 },
      { speedMultiplier: 1.9, count: 8 },
      { speedMultiplier: 2.0, count:13 },
    ],
    [ENEMY_TYPE.Skeleton]: [
      { speedMultiplier: 1, count: 1 },
      { speedMultiplier: 1.1, count:2},
      { speedMultiplier: 1.2, count: 3 },
      { speedMultiplier: 1.3, count:3 },
      { speedMultiplier: 1.4, count:4},
      { speedMultiplier: 1.6, count: 4},
      { speedMultiplier: 1.7, count: 5},
      { speedMultiplier: 1.8, count: 6 },
      { speedMultiplier: 1.9, count: 8 },
      { speedMultiplier: 2.0, count:13 },
    ],
  }
}

export { GAME_CONFIG, ROUND_CONFIG };
