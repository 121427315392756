import { DIRECTION } from "./game-data";
import {randomBetween} from "../../../../core/helpers/helpers"
const LEVEL_TYPE = {
  Level001: 'LEVEL_001',
  Level002: 'LEVEL_002',
  Level003: 'LEVEL_003',
}
// function onIntFileds(row,col){
//   let arr=[];

// }
// const fileds=onIntFileds(8,8);
// console.log(fileds,'---tem')
const LEVEL_CONFIG = {
  [LEVEL_TYPE.Level001]:
  {
    field: { rows: 8, columns: 8 },
    player: {
      startPosition: { row: 4, column: 3 },
      direction: DIRECTION.Down,
    },
    obstacles: [
        { type: 'GRAVE_01', position: { row:2, column:1 } },
        // { type: 'GRAVE_02', position: { row:2, column:1 } },
        { type: 'GRAVE_03', position: { row: 3, column:5 } }
    ],
    
  },
  [LEVEL_TYPE.Level002]:
  {
    field: { rows: 8, columns: 8 },
    player: {
      startPosition: { row: 4, column: 3 },
      direction: DIRECTION.Down,
    },
    obstacles: [
        { type: 'GRAVE_01', position: { row:3, column:6} },
        { type: 'GRAVE_02', position: { row:3, column:2} },
        { type: 'GRAVE_03', position: { row: 5, column:3 } },
        { type: 'GRAVE_04', position: { row:3, column:2} },
    ],
    
  },
}

export { LEVEL_TYPE, LEVEL_CONFIG };
